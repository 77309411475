
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center" >
		<div *ngIf="!admin && videoURL" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0em" class="offer_video_1">
			<div class="video-bg">
				<video controls id="videoplayer">
					<source [src]="videoURL" />
				</video>
			</div>
			

			<div class="video-caption" fxLayout="column" fxLayoutAlign="start start">

				<h2>TU SOCIO EN ACERO</h2>

				<p>Con mas de 30 años de experiencia en la industria, MULTIGROUP se ha posicionado como el grupo lider en acero de la región. Ofreciendo la más amplia variedad de productos derivados del acero. MULTIGROUP cuenta con 3 centros productivos, 2 en Guatemala y 1 en Nicaragua, equipados con tecnología de clase mundial operando bajo las más estrictas normas de calidad propias de la industria.</p>

				<a mat-stroked-button color="primary" class="offer_btn" href="https://multigroup.com/" target="_blank"> <img class="multi_arrow" [src]="'assets/images/multi_arrow.png'"> Ir a la página</a>
			</div>
		</div>
		<mat-card ngClass.gt-xs="mat-elevation-z6" ngClass.xs="mat-elevation-z0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" fxLayoutGap.xs="0em">

			

			<h4>Ofertas</h4>

			<div class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="1em">

				<mat-form-field *ngIf="admin" ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline" [color]="xs ? 'default': 'primary'">
					<mat-label>BUSCAR</mat-label>
					<input matInput maxlength="100" autocomplete="off" #searchInput (keyup)="applyFilter($event.target.value)">
					<app-icon icon="search_primary" matSuffix></app-icon>
				</mat-form-field>

				<button *ngIf="admin" mat-flat-button [color]="xs ? 'default' : 'primary'" (click)="newOfferDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">NUEVA OFERTA</button>

			</div>


			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay ofertas cargadas
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort [dataSource]="dataSource" class="full-width" [trackBy]="trackByFn">

				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
					<td mat-cell *matCellDef="let offer">
						{{offer.title}}
					</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de inicio</th>
					<td mat-cell *matCellDef="let offer">
						{{datesService.formattedDate(offer.date) || '-'}}
					</td>
				</ng-container>

				<ng-container matColumnDef="expiration">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Vencimiento</th>
					<td mat-cell *matCellDef="let offer">
						{{datesService.formattedDate(offer.expiration) || '-'}}
					</td>
				</ng-container>


				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let offer" class="last-column">

						<button mat-icon-button [matMenuTriggerFor]="actionsMenu" (click)="$event.stopPropagation()">
							<mat-icon>more_vert</mat-icon>
						</button>

						<mat-menu #actionsMenu="matMenu">

							<button mat-menu-item (click)="editOfferDialog(offer)">
								<mat-icon>edit</mat-icon>
								<span>Editar</span>
							</button>

							<button mat-menu-item (click)="deleteOfferDialog(offer)">
								<mat-icon>delete</mat-icon>
								<span>Eliminar</span>
							</button>

						</mat-menu>
					</td>
				</ng-container>



				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let offer; columns: displayedColumns;" class="clickable" (click)="openPreviewDialog(offer)"></tr>

			</table>

			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>
			
		</mat-card>

	</div>


	<span class="footer">©2023. Multi. Reservados Todos los Derechos.</span>

</div>