import { Component, OnInit } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { RouterService } from '../../services/router.service';
import { ApiService } from '../../services/api.service';
import { DatesService } from '../../services/dates.service';
import { DialogService } from '../../services/dialog.service';
import { OrdersService } from '../../services/orders.service';
import { AuthService } from '../../services/auth.service';
import { DatabaseService } from '../../services/database.service';
import { Tracking } from '../../classes/tracking';
import { Order } from '../../classes/order';
import { MediaObserver } from '@angular/flex-layout';
import { Page } from '../../adapters/page';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

	year : number = (new Date()).getFullYear();


	pedido : string;

	tracking : Tracking;
	messages : {mensaje: string; fecha: string;}[] = [];
	order : Order;

	orderLoaded : boolean;


	_loading : boolean = false;

	get loading() : boolean {return this.tracking == null || this.orderLoaded != true || this._loading;}

	set loading(value: boolean) {this._loading = value;}

	constructor(private routerService: RouterService,
				private authService: AuthService,
				private database: DatabaseService,
				public datesService: DatesService,
				private dialogService: DialogService,
				public ordersService: OrdersService,
				private api: ApiService,
				private media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd)
			{
				this.pedido = event.snapshot.url[event.snapshot.url.length - 1].path;

				this.api.tracking(this.pedido).then(tracking => {

					this.tracking = tracking.tracking;
					this.messages = tracking.mensajes;
				});

				this.ordersService.orderWithPurchase(this.pedido).subscribe(order => {

					this.order = order;
					this.orderLoaded = true;
				});
			}
		});
	}


	get router() {return this.routerService.router;}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	stringToDate(string: string) : Date {

		const doo = new Date(string);

		return new Date( doo.getTime() - doo.getTimezoneOffset() * -60000 );
	}

	ngOnInit() {

	}


	getDocuments(field: string) {

		var docs : {name: string, url: string}[] = [];

		if (field == 'despacho')
		{
			for (var despacho of this.tracking.despacho.documentos || [])
			{
				docs.push({
					name: despacho.numero,
					url: despacho.url
				});
			}
		}
		else if (field == 'factura')
		{
			for (var factura of this.tracking.factura.documentos || [])
			{
				docs.push({
					name: factura.numero,
					url: 'https://gc.multigroup.com/'+factura.url
				});
			}
		}
		else if (field == 'adicionales')
		{
			for (var adicional of this.tracking.adicionales.documentos || [])
			{
				docs.push({
					name: (adicional.url as string).substring((adicional.url as string).lastIndexOf('/') + 1),
					url: adicional.url
				});
			}
		}


		if (docs.length > 0)
		{
			this.dialogService.openDialog({
				type: 'documents',
				object: {docs: docs}
			});
		}
	}


}
