import { Injectable } from '@angular/core';
import { Order, OrderData } from '../classes/order';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DatabaseService, WhereFilter } from '../services/database.service';
import { StorageService } from '../services/storage.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

	orders : BehaviorSubject<Order[]> = new BehaviorSubject(null);

	constructor(private database: DatabaseService,
				private authService: AuthService,
				private snackbar: SnackbarService,
				private storageService: StorageService) {

		this.authService.userBS.subscribe(user => {

			if (user) this.init();
		});
	}

	init() {

		const filters : WhereFilter[] = [];

		if (this.authService.user.admin != true)
		{
			const companyCode = this.authService.user.code;
			
			filters.push({
				field: 'companyCode', op: '==', value: companyCode
			});
		}

		this.database.array<OrderData>('orders', 'subscribe', null, filters).subscribe(result => {

			if (result)
			{
				for (var order of result)
				{
					if (order.date && order.date['getTime'] == null)
						order.date = (order.date as firebase.firestore.Timestamp).toDate();
				}

				this.orders.next(result);
			}
		});
	}

	get(orderID: string) : Promise<Order> {

		var promise = new Promise<Order>((resolve, reject) => {

			this.database.get<Order>('orders', orderID).then(order => {

				if (order.date && order.date['getTime'] == null)
					order.date = (order.date as firebase.firestore.Timestamp).toDate();

				resolve(order);

			}).catch(reason => {

				reject(reason);
			})
		});

		return promise;
	}

	getFromArray(orderID: string) : Order {

		if (this.orders.value && this.orders.value.length > 0)
		{
			var order : Order = null;

			for (var _order of this.orders.value)
			{
				if (_order.id == orderID)
				{
					order = _order;
					break;
				}
			}

			return order;
		}
		else
		{
			return null
		}
	}


	add(order: OrderData, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.database.add("orders", order, silent ? null : {
				success: "Orden de pago generada",
				error: "Error al generar orden de pago"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	set(order: Order, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			const orderData : OrderData = {...order};

			this.database.set('orders', order.id, orderData, silent ? null : {
				success: "Orden de pago guardada",
				error: "Error al guardar orden de pago"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	delete(order: Order, silent?: boolean) : Promise<any> {

		var promise = new Promise<any>((resolve, reject) => {

			this.database.delete('orders', order.id, silent ? null : {
				success: "Orden de pago eliminada",
				error: "Error al eliminar orden de pago"
			}).then(result => {
				resolve(result);
			}).catch(reason => {
				reject(reason);
			});
		});

		return promise;
	}


	orderWithPurchase(purchase: string) : BehaviorSubject<Order> {

		const bs : BehaviorSubject<Order> = new BehaviorSubject(null);

		this.database.array<OrderData>('orders', 'subscribe', null, {
			field: 'purchases', op: 'array-contains', value: purchase
		}).subscribe(result => {

			if (result && result.length > 0)
			{
				bs.next(result[0]);
			}
		});

		return bs;
	}


	orderStatus(order: Order) : string {

		return order.paid ? 'Pagado' : (order.file ? 'Pago en revisión' : 'Pago pendiente');
	}
}
