import { Component, OnInit,ChangeDetectorRef} from '@angular/core';
  import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(
    private mediaMatcher: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
  ) 
  {
    this.mobileQuery = mediaMatcher.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}
