import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, AuthUserData, LoginStatus } from '../services/auth.service';
import { User, UserData } from '../classes/user';
import { ValidatorsService } from '../services/validators.service';
import { UsersService } from '../services/users.service';
import { RouterService } from '../services/router.service';
import { ApiService } from '../services/api.service';
import { Subscription } from 'rxjs';
import { Page } from '../adapters/page';



@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	method : string;

	loading : boolean = true;;

	form: FormGroup;


	show : any = {password: false, repeat: false};


	constructor(private formBuilder: FormBuilder,
				private validatorsService: ValidatorsService,
				private usersService: UsersService,
				private api: ApiService,
				private routerService: RouterService,
				public authService: AuthService,
				private page: Page) {

		this.page.actionBarHidden = true;

		this.loading = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd)
			{
				if (event.snapshot.url && event.snapshot.url.length > 0)
				{
					this.method = event.snapshot.url[0].path;
				}
				else
				{
					this.method = 'signup';
				}

				this.createForm();
			}
		});
	}

	get router() {return this.routerService.router;};

	ngOnInit() {

	}

	createForm() {

		if (this.method == 'signup')
		{
			this.form = this.formBuilder.group({
				code: ['', [Validators.required]],
				email: ['', [Validators.required, Validators.email]],
				username: ['', [Validators.required, this.validatorsService.textPattern]],
				password: ['', [Validators.required]],
				repeat: ['', [Validators.required]]
			});

			this.form.get('repeat').setValidators([
				Validators.required,
				this.validatorsService.checkPasswords(this.form.get('password'))
			]);
		}
		else
		{
			this.form = this.formBuilder.group({
				code: ['', [Validators.required]]
			});
		}

		this.loading = false;
	}


	toggleShow(event, field: string) {

		if (event.stopPropagation) event.stopPropagation();
		if (event.preventDefault) event.preventDefault();

		this.show[field] = this.show[field] != true;
	}


	ngOnDestroy() {

	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.loading = true;


		const code = this.form.get('code').value;


		this.api.company(code).then(result => {

			if (!result)
			{
				this.form.get('code').setErrors({invalid: true});
				this.loading = false;
				return;
			}

			if (this.method == 'signup')
			{
				this.formSignup();
			}
			else
			{
				this.facebookSignUp()
			}

		}).catch(reason => {

			this.form.get('code').setErrors({invalid: true});
			this.loading = false;
			return;
		});
	}


	formSignup() {

		this.authService.emailSignUp(
			this.form.get('username').value + '@multigroup-sa.web.app',
			this.form.get('password').value,
			false
		).then((result) => {

			this.complete();

		}).catch(reason => {

			this.loading = false;
		});
	}


	facebookSignUp() {

		if (this.authService.user == null)
		{
			this.authService.errorMessage = "No iniciaste sesión";
			this.authService.loginStatus = LoginStatus.Error;
		}

		const user : User = {...this.authService.user};

		user.code = this.form.get('code').value;

		this.usersService.set(user, true).then(result => {

			this.authService.userBS.next(user);

			this.router.navigate(['/']);

		}).catch(reason => {

			this.loading = false;

		});
	}





	complete(userData?: AuthUserData) {

		var user : UserData;

		if (userData)
		{
			user = {...userData, code: this.form.get('code').value};
		}
		else
		{
			user = {
				email: this.form.get('email').value,
				username: this.form.get('username').value,
				code: this.form.get('code').value
			}
		}

		this.authService.register(user).then(result => {

			if (result == "success")
			{
				this.router.navigate(['/']);
			}
			else
			{
				this.loading = false;
			}

		}).catch(reason => {

			this.loading = false;
		});
	}

}
