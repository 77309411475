
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="tracking-content" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6 content-card" ngClass.xs="content-card mat-elevation-z0" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="3em">

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<div *ngIf="!loading" class="claim-container" fxLayout="row" fxLayoutAlign="end end">

				<mat-card class="claim clickable mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="2em" [routerLink]="'/claim/'+order">

					<div class="claim-text-container" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="0.5em">

						<h4>¿Necesitas ayuda?</h4>
						<span>Comunícate con nosotros</span>

					</div>

					<div class="claim-image-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="xs ? 'chat' : 'chat_primary'" size="24"></app-icon>
					</div>

				</mat-card>

			</div>


			<!-- <div *ngIf="!loading" class="details" fxLayout="column" fxLayoutAlign="start center">

				<span class="empty" *ngIf="messages.length < 1">No hay mensajes registrados para este pedido</span>

				<div *ngFor="let message of messages" class="step active" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2em">

					<div fxLayout="column" fxLayoutAlign="center center">
						<div class="line top"></div>
						<div class="line bottom"></div>
						<div class="circle" fxLayout="column" fxLayoutAlign="center center">
							<app-icon icon="check_primary" size="32"></app-icon>
						</div>
					</div>

					<div class="message" fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.25em">
						<p>• {{message.mensaje}}</p>
						<p class="date">{{message.fecha.split('T')[0]}}</p>
					</div>

				</div>

			</div> -->

			<br/>



			<div *ngIf="!loading" class="progress files pt-2" fxLayout="row" fxLayoutAlign="center start" style="height: 7rem;">

				<div class="step" [ngClass]="{'active': tracking.factura.generado}" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center" (click)="getDocuments('factura')">
						<app-icon icon="receipt" size="32"></app-icon>
					</div>

					<span>FACTURA</span>

				</div>

				<div class="step" [ngClass]="{'active': tracking.despacho.generado}" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center" (click)="getDocuments('despacho')">
						<app-icon icon="dispatch" size="32"></app-icon>
					</div>

					<span>DESPACHO</span>

				</div>


				<div class="step" [ngClass]="{'active': tracking.adicionales.generado}" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center" (click)="getDocuments('adicionales')">
						<app-icon icon="docs" size="32"></app-icon>
					</div>

					<span>DOC. ADICIONALES</span>

				</div>

				<div class="step" [ngClass]="{'active': order && order.paid}" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon icon="money" size="32"></app-icon>
					</div>

					<span>REALIZAR PAGO</span>

				</div>

			</div>


		</mat-card>

	</div>


	<span fxHide.xs class="footer" ngClass.xs="primary">©2023. Multi. Reservados Todos los Derechos.</span>

</div>