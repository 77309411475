<div class="main-container" [class.example-is-mobile]="mobileQuery.matches">
  <!-- [opened]="sidebarToggleState" -->
  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 1 : 0"
  >
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
      [opened]="true"
    >
      <div class="menu-inner">
        <div class="menu-top">
          <a routerLink="" class="menu-logo">
            <img src="/assets/images/new-logo.svg" />
          </a>

          <div class="menu-links">
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />PANEL DE CONTROL</a
              >
            </mat-nav-list>
            <mat-nav-list class="submenu-wrap">
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />GESTION
                FINANCIERA</a
              >
            </mat-nav-list>

            <div class="submenu">
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />ESTADO DE
                  CUENTA</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />FACTURAS Y
                  PAGOS</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />ORDENES DE
                  PAGO</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />PLANES DE PAGO</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />GESTIONES
                  CREDITICIAS</a
                >
              </mat-nav-list>
            </div>

            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />COMPRAS</a
              >
            </mat-nav-list>
          </div>
        </div>

        <div class="menu-bottom">
          <div class="menu-links">
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />MUILTIGROUP.COM</a
              >
            </mat-nav-list>
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />MI CUENTA</a
              >
            </mat-nav-list>
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />CERRAR SESION</a
              >
            </mat-nav-list>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="left-sidenav-content">
      <button
        mat-icon-button
        (click)="snav.toggle()"
        fxLayout.gt-sm="none"
        class="hamburger-icon"
      >
        <mat-icon>menu</mat-icon>
      </button>

      <br />
      <mat-card class="custum-card">
        <h1>Contactos</h1>

        <div class="sales-team-wrap">
          <div class="sales-team-box">
            <div class="">
              <img src="/assets/images/login_bg.jpg" />
            </div>
            <div class="sales-team-info">
              <h3>
                JuanCarlos <br />
                gonlazez
              </h3>
              <h4>asesor</h4>
              <p>correoo electrocino</p>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/multi_arrow.png" />
                  </span>
                  <a href="mailto:juan.gonlazez@multi.com"
                    >juan.gonlazez@multi.com</a
                  >
                </li>
              </ul>
              <p>Telefono</p>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/multi_arrow.png" />
                  </span>
                  <a href="tel:+ (502) 1234567">+ (502) 1234567 </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="sales-team-box">
            <div class="">
              <img src="/assets/images/login_bg.jpg" />
            </div>
            <div class="sales-team-info">
              <h3>
                JuanCarlos <br />
                gonlazez
              </h3>
              <h4>asesor</h4>
              <p>correoo electrocino</p>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/multi_arrow.png" />
                  </span>
                  <a href="mailto:juan.gonlazez@multi.com"
                    >juan.gonlazez@multi.com</a
                  >
                </li>
              </ul>
              <p>Telefono</p>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/multi_arrow.png" />
                  </span>
                  <a href="tel:+ (502) 1234567">+ (502) 1234567 </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="sales-team-box">
            <div class="">
              <img src="/assets/images/login_bg.jpg" />
            </div>
            <div class="sales-team-info">
              <h3>
                JuanCarlos <br />
                gonlazez
              </h3>
              <h4>asesor</h4>
              <p>correoo electrocino</p>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/multi_arrow.png" />
                  </span>
                  <a href="mailto:juan.gonlazez@multi.com"
                    >juan.gonlazez@multi.com</a
                  >
                </li>
              </ul>
              <p>Telefono</p>
              <ul>
                <li>
                  <span>
                    <img src="/assets/images/multi_arrow.png" />
                  </span>
                  <a href="tel:+ (502) 1234567">+ (502) 1234567 </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </mat-card>

      <div class="copyright-box">
        <p>© 2023. Multi. Reservados Todos los Derechos.</p>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
