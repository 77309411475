import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { DialogService } from '../services/dialog.service';
import { RouterService } from '../services/router.service';
import { UserData } from '../classes/user';
import { Page } from '../adapters/page';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	form: FormGroup;


	loading : boolean = false;

	step : 'login' | 'email' = 'login';


	constructor(public authService: AuthService,
				private formBuilder: FormBuilder,
				private dialogService: DialogService,
				private routerService: RouterService,
				private page: Page) {

		this.createForm();

		this.page.actionBarHidden = true;
	}


	get router() {return this.routerService.router;};


	createForm() {

		this.form = this.formBuilder.group({
			username: ['', [Validators.required]],
			password: ['', [Validators.required]],
			keepSession: [true, []]
		});
	}

	ngOnInit() {

	}


	formLogin() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.loading = true;

		const username = this.form.get('username').value + '@multigroup-sa.web.app';

		this.authService.emailLogin(username, this.form.get('password').value, this.form.get('keepSession').value).then(result => {

			this.router.navigate(['/']);

		}).catch(reason => {
			this.loading = false;
		});
	}


	facebookLogin() {

		this.loading = true;

		this.authService.socialLogin('facebook', this.form.get('keepSession').value).then((user) => {

			if (user)
			{
				this.router.navigate(['/']);
			}
			else
			{
				this.authService.authUserData().then(authUserData => {

					var userData : UserData = {...authUserData};

					this.authService.register(userData).then(result => {

						if (result == "success")
						{
							this.router.navigate(['/']);
						}
						else
						{
							this.loading = false;
						}
					});

				}).catch(reason => {
					this.loading = false;
				});
			}

		}).catch(reason => {
			this.loading = false;
		});
	}



	resetPasswordDialog() {

		this.dialogService.openDialog({
			type: 'resetPassword',
			confirm: this.resetPassword.bind(this)
		});
	}


	resetPassword() {

		this.dialogService.closeDialog();
	}

}
