import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserData, User } from '../../classes/user';
import { AuthService } from '../../services/auth.service';
import { DatabaseService } from '../../services/database.service';
import { SnackbarService } from '../../services/snackbar.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.detectChanges();
	}


	saving : boolean = false;


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private authService: AuthService,
				private http: HttpClient,
				private database: DatabaseService,
				private changeDetectorRef: ChangeDetectorRef,
				private snackbar: SnackbarService) {

	}

	ngOnInit() {

		this.form = this.formBuilder.group({
			username: ['', [Validators.required]]
		});
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.setLoading(true);


		this.database.array<UserData>('users', 'get', null, {
			field: 'username', op: '==', value: this.form.get('username').value
		}).subscribe(result => {

			if (result)
			{
				if (result.length > 0)
				{
					const url = 'https://us-central1-multigroup-sa.cloudfunctions.net/resetPassword?user='+result[0].id;

					this.http.get(url, {responseType: 'json'}).subscribe((res : any) => {

						if (res.ok)
						{
							this.snackbar.show("Contraseña cambiada");
							this.confirm();
						}
						else
						{
							this.snackbar.show("Error al recuperar contraseña");
							this.setLoading(false);
						}

					}, err => {
						this.snackbar.show("Error al recuperar contraseña");
						this.setLoading(false);
					});
				}
				else
				{
					//this.form.get('username').setErrors({invalid: true});
					this.snackbar.show("Usuario inválido");
					this.setLoading(false);
				}
			}

		}, err => {
			this.snackbar.show("Error al recuperar contraseña");
			this.setLoading(false);
		});
	}

}
