<!-- <mat-toolbar ngClass.gt-xs="mat-elevation-z5" [ngClass.xs]="bgcolor" [class]="forceColor ? bgcolor : ''">
	<mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
		<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.2em">
			<img class="logo clickable"
				[src]="'assets/images/multi'+(bgcolor == 'primary' && (xs && !forceColor) ? '' : '_red1')+'.png'"
				alt="Multi" (click)="openHome()" />

		</div>
		<div fxHide.xs fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1.5em">

			<div *ngIf="!forceColor || xs" class="clickable" fxLayout="row" fxLayoutAlign="center center"
				[routerLink]="homePath+'profile'">
				<span>{{username}}</span>
				<div class="photo" fxLayout="row" fxLayoutAlign="center center"
					[ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}">
				</div>
			</div>

			<div class="clickable" fxLayout="row" fxLayoutAlign="center center" (click)="signOut()">
				<span>Salir</span>
				<img class="exit" src="assets/images/exit.png" alt="Exit" />
			</div>
		</div>


		<div fxHide.gt-xs>

			<div class="photo xs" fxLayout="row" fxLayoutAlign="center center"
				[ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}"
				[matMenuTriggerFor]="navMenu" (click)="$event.stopPropagation()">
			</div>

			<mat-menu #navMenu="matMenu">

				<button mat-menu-item [routerLink]="homePath+'profile'" fxLayout="row" fxLayoutAlign="start center"
					fxLayoutGap="1em">
					<app-icon icon="user_primary" size="24"></app-icon>
					<span>Perfil ({{username}})</span>
				</button>

				<button mat-menu-item (click)="signOut()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
					<app-icon icon="exit" size="24"></app-icon>
					<span>Salir</span>
				</button>

			</mat-menu>
		</div>

	</mat-toolbar-row>
</mat-toolbar> -->



<!-- <div class="home newHOme" ngClass.xs="xs" ngClass.gt-xs="content" ngClass.xs="full-width">

	<div class="row">
		<div class="col-lg-4 borderrightdown">
			<div style="margin: 40px;" class="section" [ngClass]="{'hidden': section(0)?.hidden, 'disabled': section(0)?.disabled}"
				fxLayout="column" fxLayoutAlign="start center" (click)="openSection(0)">
				<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
					<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="section(0)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
					</div>
				</div>
				<span class="title">{{section(0)?.text}}</span>
			</div>
		</div>
		<div class="col-lg-4 borderrightdown">
			<div style="margin: 40px;" class="section"
				[ngClass]="{'hidden': section(4)?.hidden, 'disabled': section(4)?.disabled, 'big': sections?.length < 6}"
				fxLayout="column" fxLayoutAlign="start center" (click)="openSection(4)">
				<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
					<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="section(4)?.icon" [size]="xs ? (sections?.length < 6 ? 3.5 : 2.5) : 4.5"
							sizeUnit="em"></app-icon>
					</div>
				</div>
				<span class="title">{{section(4)?.text}}</span>
			</div>
		</div>
		<div class="col-lg-4 borderdown">
			<div style="margin: 40px;" class="section" [ngClass]="{'hidden': section(1)?.hidden, 'disabled': section(1)?.disabled}"
				fxLayout="column" fxLayoutAlign="start center" (click)="openSection(1)">
				<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
					<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="section(1)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
					</div>
				</div>
				<span class="title">{{section(1)?.text}}</span>
			</div>

		</div>
	</div>
	<div class="row">
		<div class="col-lg-4 borderright"
		[ngClass]="{'borderdown' : xs}">
			<div style="margin: 40px;" class="section" [ngClass]="{'hidden': section(2)?.hidden, 'disabled': section(2)?.disabled}"
				fxLayout="column" fxLayoutAlign="start center" (click)="openSection(2)">
				<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
					<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="section(2)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
					</div>
				</div>
				<span class="title">{{section(2)?.text}}</span>
			</div>

		</div>
		<div class="col-lg-4 borderright"
		[ngClass]="{'borderdown' : xs}">

			<div style="margin: 40px;" *ngIf="sections?.length > 5" class="section"
				[ngClass]="{'hidden': section(5)?.hidden, 'disabled': section(5)?.disabled}" fxLayout="column"
				fxLayoutAlign="start center" (click)="openSection(5)">
				<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
					<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="section(5)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
					</div>
				</div>
				<span class="title">{{section(5)?.text}}</span>
			</div>

		</div>
		<div class="col-lg-4 "
		[ngClass]="{'borderdown' : xs}">

			<div style="margin: 40px;" class="section" [ngClass]="{'hidden': section(3)?.hidden, 'disabled': section(3)?.disabled}"
				fxLayout="column" fxLayoutAlign="start center" (click)="openSection(3)">
				<div class="icon-border" fxLayout="column" fxLayoutAlign="center center">
					<div class="icon-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="section(3)?.icon" [size]="xs ? 2.5 : 4.5" sizeUnit="em"></app-icon>
					</div>
				</div>
				<span class="title">{{section(3)?.text}}</span>
			</div>
		</div>
	</div>

	

	<span class="footer" style="color: white;">©2023. Multi. Reservados Todos los Derechos.</span>

</div> -->

<div class="main-container" [class.example-is-mobile]="mobileQuery.matches">
	
		
	
	
<!-- [opened]="sidebarToggleState" -->
		<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 1 : 0">
			<mat-sidenav #snav  [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
			fixedTopGap="56">
			

			<div class="menu-inner">
				<div class="menu-top">
					
					<a routerLink="" class="menu-logo">
						<img src="/assets/images/new-logo.svg"/>
					</a>

					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />PANEL DE CONTROL</a>
						</mat-nav-list>
						<mat-nav-list class="submenu-wrap">
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTION FINANCIERA</a>
						</mat-nav-list>

						<div class="submenu">
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ESTADO DE CUENTA</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />FACTURAS Y PAGOS</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ORDENES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />PLANES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTIONES CREDITICIAS</a>
							</mat-nav-list>
						</div>

						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />COMPRAS</a>
						</mat-nav-list>
					</div>
					
				</div>

				<div class="menu-bottom">
					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MUILTIGROUP.COM</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MI CUENTA</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />CERRAR SESION</a>
						</mat-nav-list>
					</div>
				</div>
			</div>
		</mat-sidenav>

		<mat-sidenav-content class="left-sidenav-content">
			<button mat-icon-button (click)="snav.toggle()" fxLayout.gt-sm="none" class="hamburger-icon"><mat-icon>menu</mat-icon></button>
			<!-- <mat-card class="custum-card">
				<h1>BIENVENIDO, USUARIO</h1>
				<div class="home-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayout.sm="column">
				
						
					<div class="question-box row-font">
						<img src="/assets/images/ques-info.png" />
						NECESITAS AYUDA?
					</div>
					<div class="no-box row-font">
						<img src="/assets/icons/phone-icon.svg" />
						2320-5555
					</div>
					<div>
						<button mat-button class="home-btn bg-red">
							<img src="/assets/images/plus-sign.png"/>
							<span>CREAR TICKET</span>
						</button>
					</div>
					<div>
						<button mat-button class="home-btn">
							<img src="/assets/images/plus-sign.png" />
							<span>HABLAR CON ALGUIEN</span>
						</button>
					</div>
				</div>

				<div class="home-image-box">
					<div class="image-box-inner">
						<div  fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
							<div class="">
								<img src="../../assets/images/left.png" />
							</div>
							<div class="">
								<img src="../../assets/images/right.png" />
							</div>
						</div>
					</div>
				</div>
				
			</mat-card> -->


			<!-- product  -->
			<!-- <mat-card class="custum-card">
				<h1>PRODUCTOS</h1>
				<div class="edit-link" fxLayout="row" fxLayoutAlign="end">
					<a [routerLink]="">Regresar</a>
				</div>

				<div class="product-box-wrap" fxLayout="row">
					<a [routerLink]="" class="product-box" style="background-image: url('../../assets/images/p1.png');">
						<div class="product-layer"></div>
						<div class="product-info">
							<p>TITULO LINEA</p>
							<span>Ver Productos</span>
						</div>
					</a>
					<a [routerLink]="" class="product-box" style="background-image: url('../../assets/images/p2.png');">
						<div class="product-layer"></div>
						<div class="product-info">
							<p>TITULO LINEA</p>
							<span>Ver Productos</span>
						</div>
					</a>
					<a [routerLink]="" class="product-box" style="background-image: url('../../assets/images/p3.png');">
						<div class="product-layer"></div>
						<div class="product-info">
							<p>TITULO LINEA</p>
							<span>Ver Productos</span>
						</div>
					</a>
					<a [routerLink]="" class="product-box" style="background-image: url('../../assets/images/p4.png');">
						<div class="product-layer"></div>
						<div class="product-info">
							<p>TITULO LINEA</p>
							<span>Ver Productos</span>
						</div>
					</a>
					<a [routerLink]="" class="product-box" style="background-image: url('../../assets/images/p5.png');">
						<div class="product-layer"></div>
						<div class="product-info">
							<p>TITULO LINEA</p>
							<span>Ver Productos</span>
						</div>
					</a>
					<a [routerLink]="" class="product-box" style="background-image: url('../../assets/images/p6.png');">
						<div class="product-layer"></div>
						<div class="product-info">
							<p>TITULO LINEA</p>
							<span>Ver Productos</span>
						</div>
					</a>
				</div>
			</mat-card> -->


			<!-- puechase -->
			<!-- <mat-card class="custum-card">

			
				<h1>SEGUIMIENTO DE COMPRAS</h1>

				<div class="purchase-table">
					<div class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px" fxLayout.xs="column" fxLayout.sm="column">
						<div class="data-input">
							<input type="date" />
						</div>
						<div class="button-res" fxLayout="row nowrap" fxLayoutGap="12px" fxLayout.xs="column" fxLayout.sm="column">
							<button mat-button class="home-btn bg-red">
								<img src="/assets/images/plus-sign.png" />
								<span>ULTIMO MES</span>
							</button>
							<button mat-button class="home-btn">
								<img src="/assets/images/refresh-icon.svg" />
								<span>ULTIMOS 8 DIAS</span>
							</button>
						</div>

						
					</div>
					<div class="custum-table">
						<table mat-table [dataSource]="dataSource" class="mobile-table">
						
							
							<ng-container matColumnDef="pedido">
								<th mat-header-cell *matHeaderCellDef> Pedido </th>
								<td mat-cell *matCellDef="let element" class="listing-no"> <a routerLink=""> {{element.pedido}} </a></td>
							</ng-container>
						
						
							<ng-container matColumnDef="empresa">
								<th mat-header-cell *matHeaderCellDef> Empresa </th>
								<td mat-cell *matCellDef="let element"> {{element.empresa}} </td>
							</ng-container>
						
							
							<ng-container matColumnDef="fecha">
								<th mat-header-cell *matHeaderCellDef> Fecha </th>
								<td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
							</ng-container>
						
						
							<ng-container matColumnDef="estado">
								<th mat-header-cell *matHeaderCellDef> Estado </th>
								<td mat-cell *matCellDef="let element"> <button mat-button color="primary">{{element.estado}}</button> </td>
							</ng-container>

							<ng-container matColumnDef="proximoestado">
								<th mat-header-cell *matHeaderCellDef> Proximo Estado </th>
								<td mat-cell *matCellDef="let element"> <button mat-button color="success">{{element.pedido}}</button> </td>
							</ng-container>

							<ng-container matColumnDef="action">
								<th mat-header-cell *matHeaderCellDef> </th>
								<td mat-cell *matCellDef="let element"> 
									
									<app-icon icon="search_primary" size="16" class="clickable" ></app-icon>
									
							</ng-container>
						
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>

					
					</div>
				</div>
			</mat-card> -->


			<mat-card class="custum-card">
				<h1>SEGUIMIENTO DE COMPRAS</h1>
				<div class="edit-link" fxLayout="row" fxLayoutAlign="end">
					<a [routerLink]="">Regresar</a>
				</div>

				<div class="purchase-table">

					<div class="new-progress" fxLayout="column" fxLayoutAlign="center start">
						<h4>NO. DE PEDIDO: 200372018</h4>
						<div class="progress-line">
							<div class="red-line"></div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg"/>
								</div>
								<div class="progress-text">Confirmado</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">Programado</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">Despachado</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">En Ruta</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">Entregado</div>
							</div>
						</div>
					
					
					</div>
					<div class="detailed-table">
						<h3>DETALLE DEL PEDIDO</h3>

						<div class="custum-table">
							<table mat-table [dataSource]="dataSourceTwo" class="mobile-table">
							
							
								<ng-container matColumnDef="descr">
									<th mat-header-cell *matHeaderCellDef> Descripcion </th>
									<td mat-cell *matCellDef="let element"> {{element.descr}}</td>
								</ng-container>
								<ng-container matColumnDef="solicitado">
									<th mat-header-cell *matHeaderCellDef> Solicitado </th>
									<td mat-cell *matCellDef="let element"> {{element.solicitado}}</td>
								</ng-container>
								<ng-container matColumnDef="despacho">
									<th mat-header-cell *matHeaderCellDef> Despacho </th>
									<td mat-cell *matCellDef="let element"> {{element.despacho}}</td>
								</ng-container>
								<ng-container matColumnDef="facturado">
									<th mat-header-cell *matHeaderCellDef> Facturado </th>
									<td mat-cell *matCellDef="let element"> {{element.facturado}}</td>
								</ng-container>
							
							
							
							
								<tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumnsTwo;"></tr>
							</table>
						</div>
					</div>

					<div class="detailed-table">
						<h3>DOCUMENTACION</h3>
					
						<div class="custum-table">
							<table mat-table [dataSource]="dataSourceThree" class="mobile-table">
					
					
								<ng-container matColumnDef="tipodedocumento">
									<th mat-header-cell *matHeaderCellDef> Tipo de documento </th>
									<td mat-cell *matCellDef="let element"> {{element.tipodedocumento}}</td>
								</ng-container>
								
								<ng-container matColumnDef="estado">
									<th mat-header-cell *matHeaderCellDef> Estado </th>
									<td mat-cell *matCellDef="let element">
										<button mat-button class="download-btn">
											<img src="../../assets/icons/download-icon.svg"/>
											{{element.estado}}
										</button>
									</td>
								</ng-container>
					
					
					
								<tr mat-header-row *matHeaderRowDef="displayedColumnsThree"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumnsThree;"></tr>
							</table>
						</div>
					</div>
				</div>
			</mat-card>
			<div class="copyright-box">
				<p>© 2023. Multi. Reservados Todos los Derechos.</p>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>




