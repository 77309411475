
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6" ngClass.xs="mat-elevation-z0 full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em">

			<br *ngIf="loading" />

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>

			<h4 *ngIf="!loading">Vendedores</h4>

			<div *ngIf="!loading" class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center"></div>


			<p class="empty-text" *ngIf="!loading && vendedores.data?.length < 1">
				No hay vendedores registrados
			</p>


			<table [ngClass]="{'hidden': loading || vendedores.data?.length < 1}" mat-table matSort [dataSource]="vendedores" class="full-width" [trackBy]="trackByFn" multiTemplateDataRows>

				<ng-container matColumnDef="nombre">
					<th mat-header-cell *matHeaderCellDef>Nombre</th>
					<td mat-cell *matCellDef="let person">
						{{person.nombre}}
					</td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef>Email</th>
					<td mat-cell *matCellDef="let person">
						{{person.email}}
					</td>
				</ng-container>

				<ng-container matColumnDef="telefono_fijo">
					<th mat-header-cell *matHeaderCellDef>Teléfono fijo</th>
					<td mat-cell *matCellDef="let person">
						{{person.telefono_fijo}}
					</td>
				</ng-container>

				<ng-container matColumnDef="extension">
					<th mat-header-cell *matHeaderCellDef>Extensión</th>
					<td mat-cell *matCellDef="let person">
						{{person.extension}}
					</td>
				</ng-container>

				<ng-container matColumnDef="telefono_movil">
					<th mat-header-cell *matHeaderCellDef>Teléfono móvil</th>
					<td mat-cell *matCellDef="let person">
						{{person.telefono_movil}}
					</td>
				</ng-container>


				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let person" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="person == expandedElement ? 'expanded' : 'collapsed'" fxLayout="column">

							<br/>
							
							<p><strong>Email: </strong>{{person.email}}</p>
							<p><strong>Teléfono fijo: </strong>{{person.telefono_fijo}}</p>
							<p><strong>Extensión: </strong>{{person.extension}}</p>
							<p><strong>Teléfono móvil: </strong>{{person.telefono_movil}}</p>

						</div>
					</td>
				</ng-container>


				<tr fxHide.xs mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let person; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === person" (click)="expandedElement = expandedElement === person || !xs ? null : person"></tr>
				<tr mat-row *matRowDef="let person; columns: ['expanded']" class="detail-row"></tr>

			</table>


			<br/>


			<h4 *ngIf="!loading">Facturadores</h4>

			<div *ngIf="!loading" class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center"></div>



			<p class="empty-text" *ngIf="!loading && facturadores.data?.length < 1">
				No hay facturadores registrados
			</p>


			<table [ngClass]="{'hidden': loading || facturadores.data?.length < 1}" mat-table matSort [dataSource]="facturadores" class="full-width" [trackBy]="trackByFn" multiTemplateDataRows>

				<ng-container matColumnDef="nombre">
					<th mat-header-cell *matHeaderCellDef>Nombre</th>
					<td mat-cell *matCellDef="let person">
						{{person.nombre}}
					</td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef>Email</th>
					<td mat-cell *matCellDef="let person">
						{{person.email}}
					</td>
				</ng-container>

				<ng-container matColumnDef="telefono_fijo">
					<th mat-header-cell *matHeaderCellDef>Teléfono fijo</th>
					<td mat-cell *matCellDef="let person">
						{{person.telefono_fijo}}
					</td>
				</ng-container>

				<ng-container matColumnDef="extension">
					<th mat-header-cell *matHeaderCellDef>Extensión</th>
					<td mat-cell *matCellDef="let person">
						{{person.extension}}
					</td>
				</ng-container>

				<ng-container matColumnDef="telefono_movil">
					<th mat-header-cell *matHeaderCellDef>Teléfono móvil</th>
					<td mat-cell *matCellDef="let person">
						{{person.telefono_movil}}
					</td>
				</ng-container>


				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let person" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="person == expandedElement ? 'expanded' : 'collapsed'" fxLayout="column">

							<br/>
							
							<p><strong>Email: </strong>{{person.email}}</p>
							<p><strong>Teléfono fijo: </strong>{{person.telefono_fijo}}</p>
							<p><strong>Extensión: </strong>{{person.extension}}</p>
							<p><strong>Teléfono móvil: </strong>{{person.telefono_movil}}</p>

						</div>
					</td>
				</ng-container>


				<tr fxHide.xs mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let person; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === person" (click)="expandedElement = expandedElement === person || !xs ? null : person"></tr>
				<tr mat-row *matRowDef="let person; columns: ['expanded']" class="detail-row"></tr>

			</table>

			<br/>
			
		</mat-card>

	</div>


	<span class="footer">©2023. Multi. Reservados Todos los Derechos.</span>

</div>