<!-- <mat-toolbar ngClass.gt-xs="mat-elevation-z5" [ngClass.xs]="bgcolor" [class]="forceColor ? bgcolor : ''">
		<mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
			<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.2em">
				<button mat-icon-button (click)="snav.toggle()"><mat-icon [style.color]="mobileQuery.matches ? '#ffffff' : '#ffffff'">menu</mat-icon></button>
				<img class="logo clickable"
					[src]="'assets/images/multi'+(bgcolor == 'primary' && (xs && !forceColor) ? '' : '_red1')+'.png'"
					alt="Multi" (click)="openHome()" />
	
			</div>
			<div fxHide.xs fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1.5em">
	
				<div *ngIf="!forceColor || xs" class="clickable" fxLayout="row" fxLayoutAlign="center center"
					[routerLink]="homePath+'profile'">
					<span>{{username}}</span>
					<div class="photo" fxLayout="row" fxLayoutAlign="center center"
						[ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}">
					</div>
				</div>
	
				<div class="clickable" fxLayout="row" fxLayoutAlign="center center" (click)="signOut()">
					<span>Salir</span>
					<img class="exit" src="assets/images/exit.png" alt="Exit" />
				</div>
			</div>
	
	
			<div fxHide.gt-xs>
	
				<div class="photo xs" fxLayout="row" fxLayoutAlign="center center"
					[ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}"
					[matMenuTriggerFor]="navMenu" (click)="$event.stopPropagation()">
				</div>
	
				<mat-menu #navMenu="matMenu">
	
					<button mat-menu-item [routerLink]="homePath+'profile'" fxLayout="row" fxLayoutAlign="start center"
						fxLayoutGap="1em">
						<app-icon icon="user_primary" size="24"></app-icon>
						<span>Perfil ({{username}})</span>
					</button>
	
					<button mat-menu-item (click)="signOut()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
						<app-icon icon="exit" size="24"></app-icon>
						<span>Salir</span>
					</button>
	
				</mat-menu>
			</div>
	
		</mat-toolbar-row>
	</mat-toolbar> -->
	<mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 1 : 0">
		<mat-sidenav #snav mode="side" disableClose="true" [opened]="mobileQuery.matches ? false : true">
			<mat-nav-list>
				<mat-list-item *ngFor="let section of sections; let i= index;" [ngClass]="{
					'active-list-item': url.includes(section?.path)
				 }" (click)="openSection(i)">
					<div class="sidebar-icon-container"  >
						<div class="sidebar-icon-radius" fxLayout="column" fxLayoutAlign="center center">
							<app-icon [icon]="section?.icon" [size]="xs ? 1.5 : 1.5" sizeUnit="em"></app-icon>

						</div>
					</div>
				<!-- <mat-icon mat-list-icon>{{section?.icon}}</mat-icon> -->
				<span  (click)="openSection(i)" class="title">{{section?.text}}</span>

				</mat-list-item>
			</mat-nav-list>
		</mat-sidenav>
	
		<mat-sidenav-content>
			<router-outlet></router-outlet>
		</mat-sidenav-content>
	</mat-sidenav-container>

