
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6" ngClass.xs="mat-elevation-z0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" fxLayoutGap.xs="0em">

			<h4>Estado de cuenta</h4>

			<div class="actions-bar" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1em" fxLayout.xs="column">


				<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline" [color]="xs ? 'default': 'primary'">
					<input class="clickable" #datepickerInput matInput readonly [matDatepicker]="dp" placeholder="Seleccionar mes" (click)="$event.stopPropagation(); dp.open(); setInputValue();" (focusout)="setInputValue()">
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp
					startView="multi-year"
					(yearSelected)="chosenYearHandler($event)"
					(monthSelected)="chosenMonthHandler($event, dp)"
					panelClass="example-month-picker">
					</mat-datepicker>
				</mat-form-field>


				<div fxFlex fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="1em" fxLayoutAlign.xs="space-evenly center">
					<button mat-flat-button [color]="filter == 'month' ? (xs ? 'default' : 'primary') : (xs ? 'primary' : 'default')" (click)="selectFilter('month')">
						<img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">	Último mes
					</button>

					<button mat-flat-button [color]="filter == '8days' ? (xs ? 'default' : 'primary') : (xs ? 'primary' : 'default')" (click)="selectFilter('8days')">
						Últimos 8 días
					</button>
				</div>


				<button fxFlexAlign="center" mat-flat-button [color]="(xs ? 'default' : 'primary')" (click)="download()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">DESCARGAR ESTADO DE CUENTA</button>

			</div>


			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay compras registradas
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort [dataSource]="dataSource" class="full-width" multiTemplateDataRows>

				<ng-container matColumnDef="row_id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.row_id}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_numero">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Factura</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.factura_numero}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_fecha">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Factura</th>
					<td mat-cell *matCellDef="let purchase">
						{{purchase.factura_fecha}}
					</td>
				</ng-container>

				<ng-container matColumnDef="factura_monto">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Monto</th>
					<td mat-cell *matCellDef="let purchase">
						{{numbersService.fixedNumber(purchase.factura_monto)}}
					</td>
				</ng-container>

				<ng-container matColumnDef="pagos_realizados">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Pagos realizados</th>
					<td mat-cell *matCellDef="let purchase">
						{{numbersService.fixedNumber(purchase.pagos_realizados)}}
					</td>
				</ng-container>

				<ng-container matColumnDef="saldo_pendiente">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Saldo pendiente</th>
					<td mat-cell *matCellDef="let purchase">
						{{numbersService.fixedNumber(purchase.saldo_pendiente)}}
					</td>
				</ng-container>


				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let purchase" class="last-column">

						<app-icon icon="search_primary" size="16" class="clickable" [routerLink]="'/invoices/'+purchase.pedido"></app-icon>
					</td>
				</ng-container>



				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let purchase" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="purchase == expandedElement ? 'expanded' : 'collapsed'" fxLayout="column">

							<br/>
							
							<p><strong>Fecha: </strong>{{purchase.factura_fecha.split('T')[0]}}</p>
							<p><strong>Monto: </strong>{{numbersService.fixedNumber(purchase.factura_monto)}}</p>
							<p><strong>Pagos realizados: </strong>{{numbersService.fixedNumber(purchase.pagos_realizados)}}</p>
							<p><strong>Saldo pendiente: </strong>{{numbersService.fixedNumber(purchase.saldo_pendiente)}}</p>

						</div>
					</td>
				</ng-container>



				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let purchase; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === purchase" (click)="expandedElement = expandedElement === purchase || !xs ? null : purchase"></tr>
				<tr mat-row *matRowDef="let purchase; columns: ['expanded']" class="detail-row"></tr>

			</table>


			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>

		</mat-card>

	</div>


	<span class="footer">©2023. Multi. Reservados Todos los Derechos.</span>

</div>