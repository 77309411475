import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnDestroy,
  HostListener,
  ViewChild,
} from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RouterService } from '../services/router.service';
import { Page } from '../adapters/page';
import { MediaObserver } from '@angular/flex-layout';
import { ProfileService } from '../services/profile.service';
import { MediaMatcher } from '@angular/cdk/layout';

export interface HomeSection {
  icon: string;
  text: string;
  path: string;
  module?: string;
  hidden?: boolean;
  disabled?: boolean;
}

export interface PeriodicElement {
  pedido: Number;
  empresa: string;
  fecha: string;
  estado: string;
  proximoestado: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
  {
    pedido: 200372018,
    empresa: 'Grupo Ferroso S.A.',
    fecha: 'DD / MM / AAAA',
    estado: 'Entregado',
    proximoestado: 'Entregado',
    action: '',
  },
];

export interface DetailElement {
  descr: string;
  solicitado: number;
  despacho: number;
  facturado: number;
}
const DETAIL_DATA: DetailElement[] = [
  {
    descr: 'Lamina Negra 1” x 8” x 20”',
    solicitado: 2.0,
    despacho: 2.0,
    facturado: 2.0,
  },
  {
    descr: 'Lamina Negra 1” x 8” x 20”',
    solicitado: 2.0,
    despacho: 2.0,
    facturado: 2.0,
  },
];

export interface DoucmentElement {
  tipodedocumento: string;
  estado: string;
}
const DOUCMENT_DATA: DoucmentElement[] = [
  {
    tipodedocumento: 'PEDIDO',
    estado: 'Descargar',
  },
  {
    tipodedocumento: 'DESPACHO',
    estado: 'Descargar',
  },
  {
    tipodedocumento: 'FACTURA',
    estado: 'Descargar',
  },
  {
    tipodedocumento: 'DOCUMENTOS ADICIONALES',
    estado: 'Descargar',
  },
];
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'pedido',
    'empresa',
    'fecha',
    'estado',
    'proximoestado',
    'action',
  ];
  dataSource = ELEMENT_DATA;

  displayedColumnsTwo: string[] = [
    'descr',
    'solicitado',
    'despacho',
    'facturado',
  ];
  dataSourceTwo = DETAIL_DATA;

  displayedColumnsThree: string[] = ['tipodedocumento', 'estado'];
  dataSourceThree = DOUCMENT_DATA;

  [x: string]: any;
  year: number = new Date().getFullYear();

  username: string;

  sections: HomeSection[] = [];

  @Input('bgcolor') bgcolor: string = 'default';

  @Input('forceColor') forceColor: boolean = false;

  homePath: string;

  photoUrl: string = '';

  urlLength: number = 10;

  mobileQuery: MediaQueryList;
  sidebarToggleState = true;
  @ViewChild('snav') sidenav;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from(
    { length: 50 },
    () =>
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
  );

  get showBack(): boolean {
    return this.homePath && this.urlLength > (this.homePath == '/' ? 0 : 1);
  }
  private _mobileQueryListener: () => void;
  constructor(
    private routerService: RouterService,
    private authService: AuthService,
    private media: MediaObserver,
    private page: Page,
    private profileService: ProfileService,
    private changeDetectorRef: ChangeDetectorRef,
    private mediaMatcher: MediaMatcher
  ) {
    this.mobileQuery = mediaMatcher.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.page.actionBarHidden = true;
    this.loadSections();
    // this.router.events.subscribe(event => {
    // 	console.log(event);

    // 	if (event instanceof ActivationEnd)
    // 	{

    // 	}
    // });

    this.authService.userBS.subscribe((user) => {
      if (user) {
        this.username = user.name || user.username;

        if (user.admin) {
          this.homePath = '/admin/';
        } else {
          this.homePath = '/';
        }
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.configureSideNav();
  }

  configureSideNav() {
    this.smallScreen = window.innerWidth <= 768 ? true : false;
    if (!this.smallScreen) {
      this.sidenav.mode = 'side';
      this.sidenav.opened = true;
    } else {
      this.sidenav.mode = 'over';
      this.sidenav.opened = false;
    }
  }
  get router() {
    return this.routerService.router;
  }

  get xs(): boolean {
    return this.media.isActive('xs');
  }

  get admin(): boolean {
    return this.authService.user && this.authService.user.admin;
  }

  loadSections() {
    console.log('loadSections');

    if (this.authService.user.admin) {
      this.sections = [
        {
          icon: 'user',
          text: 'Clientes administradores',
          path: '/admin/customers',
        },
        { icon: 'money', text: 'Administrar ofertas', path: '/admin/offers' },
        {
          icon: 'catalog',
          text: 'Catálogo de productos',
          path: '/admin/products',
        },
        {
          icon: 'receipt',
          text: 'Aprobar pagos realizados',
          path: '/admin/orders',
        },
      ];
    } else {
      this.sections = [
        {
          icon: 'tracking',
          text: 'Seguimiento de compras',
          path: '/purchases',
          module: 'purchases',
        },
        { icon: 'finances', text: 'Gestión financiera', path: '/finances' },
        {
          icon: 'catalog',
          text: 'Catálogo de productos',
          path: '/products',
          module: 'products',
        },
        {
          icon: 'market',
          text: 'Compras',
          path: '/shopping',
          module: 'shopping',
        },
        {
          icon: 'business',
          text: 'Información del negocio',
          path: '/company',
          module: 'company',
        },
      ];

      if (this.authService.user.role == 'admin')
        this.sections.push({
          icon: 'user',
          text: 'Administrar usuarios',
          path: '/users',
        });
    }
  }

  section(index: number): HomeSection {
    const section = this.enabledSection(index);

    if (section.module) {
      const user = this.authService.user;

      section.disabled =
        !user.admin &&
        user.role != 'admin' &&
        (user.modules == null || user.modules.indexOf(section.module) < 0);
    }

    return section;
  }

  enabledSection(index: number): HomeSection {
    if (this.sections && this.sections.length > 0) {
      if (index < this.sections.length) {
        return this.sections[index];
      } else {
        const section = { ...this.sections[0] };
        section.hidden = true;

        return section;
      }
    } else {
      return null;
    }
  }

  openSection(index: number) {
    const section = this.section(index);

    if (section.hidden || !section.path) return;

    this.router.navigate([section.path]);
  }

  ngOnInit() {
    this.profileService.photoUrl.subscribe((url) => {
      this.photoUrl = url;

      if (!this.changeDetectorRef['destroyed'])
        this.changeDetectorRef.detectChanges();
    });
    if (window.innerWidth > 768) {
      this.sidenav.opened = true;
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some((h) =>
    h.test(window.location.host)
  );

  openHome() {
    if (this.homePath) this.router.navigate([this.homePath]);
  }

  signOut() {
    this.authService.signOut();
  }
  get uploading() {
    return this.profileService.uploading;
  }
}
