import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { AuthService } from '../../services/auth.service';
import { NumbersService } from '../../services/numbers.service';
import { SessionService } from '../../services/session.service';
import { DownloadService } from '../../services/download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MediaObserver } from '@angular/flex-layout';
import { Purchase } from '../../classes/purchase';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class AccountComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	loading : boolean = true;

	displayedColumns: string[];
	dataSource: MatTableDataSource<Purchase> = new MatTableDataSource([]);

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	mediaSubscription : Subscription;


	expandedElement : Purchase;


	@ViewChild('dp', {static: true}) datepicker: MatDatepicker<Date>;
	@ViewChild('datepickerInput', {static: true}) datepickerInput : ElementRef;


	page : number = 0;
	pageSize : number = 10;


	monthDate : Date = new Date();


	filter : 'month' | '8days' = 'month';

	get user() {return this.authService.user;}

	constructor(public api: ApiService,
				private dialogService: DialogService,
				public authService: AuthService,
				public datesService: DatesService,
				public numbersService: NumbersService,
				private snackbar: SnackbarService,
				private changeDetectorRef: ChangeDetectorRef,
				public media: MediaObserver,
				private downloadService: DownloadService,
				private sessionService: SessionService,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.loading = true;

		this.updateColumns();

		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = ['row_id', 'factura_numero', 'factura_fecha', 'factura_monto', 'pagos_realizados', 'saldo_pendiente', 'actions'];
		}
		else
		{
			this.displayedColumns = ['factura_numero', 'actions'];
		}
	}


	loadData() {

		this.updateColumns();

		this.api.purchases(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1).then(result => {

			if (result)
			{
				var lastDate : Date;

				for (var purchase of result)
				{
					const purchaseDate = this.stringToDate(purchase.factura_fecha);

					if (lastDate == null || lastDate.getTime() < purchaseDate.getTime())
					{
						lastDate = purchaseDate;
					}
				}


				this.dataSource.data = result.filter(purchase => {

					if (this.filter == 'month') return true;

					if (lastDate)
					{
						const purchaseDate = this.stringToDate(purchase.factura_fecha);

						return this.datesService.daysBetween(purchaseDate, lastDate) < 8;
					}

					return true;
				});

				this.loading = false;

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();
			}

		}).catch(reason => {

			this.loading = false;

			if (!this.changeDetectorRef['destroyed'])
				this.changeDetectorRef.markForCheck();
		});
	}


	stringToDate(string: string) : Date {

		const doo = new Date(string);

		return new Date( doo.getTime() - doo.getTimezoneOffset() * -60000 );
	}



	ngOnInit() {

		if (this.paginator)
		{
			this.paginator._intl.itemsPerPageLabel = "Facturas por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) 
				{
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}


		const monthItem = this.sessionService.getItem('monthDate');

		if (monthItem)
			this.monthDate.setTime(JSON.parse(monthItem).date);


		this.filter = (this.sessionService.getItem('filter') as 'month' | '8days') || 'month';


		this.loadData();

		this.setInputValue();
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}


	trackByFn(index, item: Purchase) {
		return item.row_id;
	}


	chosenYearHandler(yearDate: Date) {

		this.monthDate.setFullYear(yearDate.getFullYear());
	}

	chosenMonthHandler(monthDate: Date, datepicker?: MatDatepicker<Date>) {

		this.monthDate.setMonth(monthDate.getMonth());
		this.monthDate.setFullYear(monthDate.getFullYear());

		this.sessionService.setItem('monthDate', JSON.stringify({date: this.monthDate.getTime()}));

		if (datepicker)
		{
			datepicker.close();
			datepicker.select(this.monthDate);
		}


		this.setInputValue();

		this.loading = true;

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();

		this.loadData();
	}


	setInputValue() {

		const newValue = this.datesService.monthName(this.monthDate) + ' ' + this.monthDate.getFullYear();

		if (this.datepickerInput)
		{
			const input = (this.datepickerInput.nativeElement as MatInput);

			input.value = newValue;
		}
	}


	selectFilter(filter: 'month' | '8days') {

		if (this.loading) return;

		this.filter = filter;

		this.sessionService.setItem('filter', this.filter);

		this.loading = true;

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();

		this.loadData();
	}



	download() {

		this.api.company(this.authService.user.code).then(company => {

			const code = company.id;

			const url = "https://gc.multigroup.com/sales/cobranza/cliente/"+code+"/byidcxcapp/";
			const name = "Estado de cuenta";

			this.downloadService.download(url, name);

		}).catch(reason => {

			this.snackbar.show("Error en la descarga");
		});
	}

}
