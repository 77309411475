<div class="main-container" [class.example-is-mobile]="mobileQuery.matches">




	<!-- [opened]="sidebarToggleState" -->
	<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 1 : 0">
		<mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
			fixedTopGap="56">


			<div class="menu-inner">
				<div class="menu-top">

					<a routerLink="" class="menu-logo">
						<img src="/assets/images/new-logo.svg" />
					</a>

					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />PANEL DE CONTROL</a>
						</mat-nav-list>
						<mat-nav-list class="submenu-wrap">
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTION FINANCIERA</a>
						</mat-nav-list>

						<div class="submenu">
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ESTADO DE CUENTA</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />FACTURAS Y PAGOS</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ORDENES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />PLANES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTIONES CREDITICIAS</a>
							</mat-nav-list>
						</div>

						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />COMPRAS</a>
						</mat-nav-list>
					</div>

				</div>

				<div class="menu-bottom">
					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MUILTIGROUP.COM</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MI CUENTA</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />CERRAR SESION</a>
						</mat-nav-list>
					</div>
				</div>
			</div>
		</mat-sidenav>

		<mat-sidenav-content class="left-sidenav-content">
			<button mat-icon-button (click)="snav.toggle()" fxLayout.gt-sm="none"
				class="hamburger-icon"><mat-icon>menu</mat-icon></button>
			


			<mat-card class="custum-card">
				<h1>SEGUIMIENTO DE COMPRAS</h1>
				<div class="edit-link" fxLayout="row" fxLayoutAlign="end">
					<a [routerLink]="">Regresar</a>
				</div>

				<div class="purchase-table">

					<div class="new-progress" fxLayout="column" fxLayoutAlign="center start">
						<h4>NO. DE PEDIDO: 200372018</h4>
						<div class="progress-line">
							<div class="red-line"></div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">Confirmado</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">Programado</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">Despachado</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">En Ruta</div>
							</div>
							<div class="progress-no">
								<div class="progress-line"></div>
								<div class="progress-box">
									<img src="../../assets/icons/step-icon.svg" />
								</div>
								<div class="progress-text">Entregado</div>
							</div>
						</div>


					</div>
					<div class="detailed-table">
						<h3>DETALLE DEL PEDIDO</h3>

						<div class="custum-table">
							<table mat-table [dataSource]="dataSourceTwo" class="mobile-table">


								<ng-container matColumnDef="descr">
									<th mat-header-cell *matHeaderCellDef> Descripcion </th>
									<td mat-cell *matCellDef="let element"> {{element.descr}}</td>
								</ng-container>
								<ng-container matColumnDef="solicitado">
									<th mat-header-cell *matHeaderCellDef> Solicitado </th>
									<td mat-cell *matCellDef="let element"> {{element.solicitado}}</td>
								</ng-container>
								<ng-container matColumnDef="despacho">
									<th mat-header-cell *matHeaderCellDef> Despacho </th>
									<td mat-cell *matCellDef="let element"> {{element.despacho}}</td>
								</ng-container>
								<ng-container matColumnDef="facturado">
									<th mat-header-cell *matHeaderCellDef> Facturado </th>
									<td mat-cell *matCellDef="let element"> {{element.facturado}}</td>
								</ng-container>




								<tr mat-header-row *matHeaderRowDef="displayedColumnsTwo"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumnsTwo;"></tr>
							</table>
						</div>
					</div>

					<div class="detailed-table">
						<h3>DOCUMENTACION</h3>

						<div class="custum-table">
							<table mat-table [dataSource]="dataSourceThree" class="mobile-table">


								<ng-container matColumnDef="tipodedocumento">
									<th mat-header-cell *matHeaderCellDef> Tipo de documento </th>
									<td mat-cell *matCellDef="let element"> {{element.tipodedocumento}}</td>
								</ng-container>

								<ng-container matColumnDef="estado">
									<th mat-header-cell *matHeaderCellDef> Estado </th>
									<td mat-cell *matCellDef="let element">
										<button mat-button class="download-btn">
											<img src="../../assets/icons/download-icon.svg" />
											{{element.estado}}
										</button>
									</td>
								</ng-container>



								<tr mat-header-row *matHeaderRowDef="displayedColumnsThree"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumnsThree;"></tr>
							</table>
						</div>
					</div>
				</div>
			</mat-card>
			<div class="copyright-box">
				<p>© 2023. Multi. Reservados Todos los Derechos.</p>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>