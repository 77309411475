
<div class="signup" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="start center">

	<div class="content" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">

		<img class="logo clickable" src="assets/images/multi.png" alt="Multi" routerLink="/login" />

		<h4>REGISTRAR NUEVA CUENTA</h4>

		<mat-spinner *ngIf="loading" color="accent" diameter="40"></mat-spinner>

		<form *ngIf="!loading" class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25em" [formGroup]="form" (ngSubmit)="save()" autocomplete="off">

			<mat-form-field class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="company"></app-icon>
				<mat-label>CÓDIGO DE CLIENTE</mat-label>
				<input matInput formControlName="code" maxlength="100">
				<mat-error *ngIf="form.get('code').hasError('required')">Código requerido</mat-error>
				<mat-error *ngIf="form.get('code').hasError('invalid')">Código inválido</mat-error>
			</mat-form-field>

			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="email"></app-icon>
				<mat-label>EMAIL</mat-label>
				<input matInput formControlName="email" maxlength="100">
				<mat-error *ngIf="form.get('email').hasError('required')">Email requerido</mat-error>
				<mat-error *ngIf="form.get('email').hasError('email')">Email inválido</mat-error>
			</mat-form-field>

			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="user"></app-icon>
				<mat-label>USUARIO</mat-label>
				<input matInput formControlName="username" maxlength="100">
				<mat-error *ngIf="form.get('username').hasError('required')">Usuario requerido</mat-error>
				<mat-error *ngIf="form.get('username').hasError('pattern')">Usuario inválido</mat-error>
			</mat-form-field>

			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="password"></app-icon>
				<mat-label>CONTRASEÑA</mat-label>
				<input matInput [type]="show['password'] == true ? 'text' : 'password'" formControlName="password" maxlength="100">
				<app-icon matSuffix class="clickable" [icon]="show['password'] == true ? 'hide' : 'show'" (click)="toggleShow($event, 'password')"></app-icon>
				<mat-error *ngIf="form.get('password').hasError('required')">Contraseña requerida</mat-error>
			</mat-form-field>

			<mat-form-field *ngIf="method == 'signup'" class="full-width" appearance="outline" color="accent">
				<app-icon matPrefix icon="password"></app-icon>
				<mat-label>CONFIRMAR CONTRASEÑA</mat-label>
				<input matInput [type]="show['repeat'] == true ? 'text' : 'password'" formControlName="repeat" maxlength="100">
				<app-icon matSuffix class="clickable" [icon]="show['repeat'] == true ? 'hide' : 'show'" (click)="toggleShow($event, 'repeat')"></app-icon>
				<mat-error *ngIf="form.get('repeat').hasError('required')">Contraseña requerida</mat-error>
				<mat-error *ngIf="form.get('repeat').hasError('notSame')">La contraseña no coincide</mat-error>
			</mat-form-field>


			<br *ngIf="method == 'fb_signup'" />
			<br *ngIf="method == 'fb_signup'" />


			<span *ngIf="authService.loginStatus == 'Error'">
				{{authService.errorMessage}}
			</span>


			<button mat-flat-button type="submit">REGISTRARSE</button>

		</form>

		<span fxFlex></span>

		<span>©2023. Multi. Reservados Todos los Derechos.</span>

	</div>

</div>