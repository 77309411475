import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProductsService } from '../../services/products.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { RouterService } from '../../services/router.service';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MediaObserver } from '@angular/flex-layout';
import { Product, ProductData } from '../../classes/product';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ActivationEnd } from '@angular/router';
import { Page } from '../../adapters/page';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ProductsComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	loading : boolean = true;

	displayedColumns: string[];
	dataSource: MatTableDataSource<Product> = new MatTableDataSource([]);

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	mediaSubscription : Subscription;
	sourceSubscription : Subscription;


	page : number = 0;
	pageSize : number = 10;


	admin : boolean = false;


	form : FormGroup



	constructor(public productsService: ProductsService,
				private formBuilder: FormBuilder,
				private dialogService: DialogService,
				public authService: AuthService,
				public datesService: DatesService,
				private changeDetectorRef: ChangeDetectorRef,
				private routerService: RouterService,
				public media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.form = this.formBuilder.group({});

		this.loading = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd)
			{
				if (event.snapshot.data.admin == true)
				{
					this.admin = true;
				}

				this.updateColumns();

				this.mediaSubscription = this.media.asObservable().subscribe(change => {
					this.updateColumns();
				});

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();

				this.loadData();
			}
		});
	}


	get router() {return this.routerService.router;}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = ['title', 'date', 'expiration'];
		}
		else
		{
			this.displayedColumns = ['title'];
		}

		if (this.admin == true)
			this.displayedColumns.push('actions');
	}


	loadData() {

		this.updateColumns();

		this.sourceSubscription = this.productsService.products.subscribe(result => {

			if (result)
			{
				if (this.admin)
				{
					this.dataSource.data = result;
				}
				else
				{
					this.dataSource.data = result.filter(product => {

						const date = new Date();

						return (!product.date || (product.date as Date).getTime() < date.getTime()) &&
								(!product.expiration || (product.expiration as Date).getTime() > date.getTime());
					});
				}

				this.loading = false;

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();
			}
		});
	}



	ngOnInit() {

		if (this.paginator)
		{
			this.paginator._intl.itemsPerPageLabel = "Productos por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) 
				{
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
		if (this.sourceSubscription) this.sourceSubscription.unsubscribe();
	}


	newProductDialog() {

		this.dialogService.openDialog({
			title: 'Nuevo producto',
			type: 'newProduct'
		});
	}


	deleteProductDialog(product: Product) {

		this.dialogService.openDialog({
			title: 'Eliminar producto',
			text: '¿Seguro que desea eliminar el producto \"'+product.title+'\"?',
			type: 'Warn',
			confirm: this.deleteProduct.bind(this),
			object: product
		});
	}

	deleteProduct(product: Product) {

		this.productsService.delete(product).then(result => {
			this.dialogService.closeDialog();
		});
	}


	editProductDialog(product: Product) {

		this.dialogService.openDialog({
			type: 'newProduct',
			object: {product: product}
		});
	}


	openPreviewDialog(product: Product) {

		this.dialogService.openDialog({
			type: 'productPreview',
			object: {product: product}
		});
	}


	trackByFn(index, item) {
		return item.id;
	}


	applyFilter(filterValue: string) {

		this.dataSource.filterPredicate = (product: Product, filter: string) => {
			return !filter ||
					(product.title && product.title.toLowerCase().replace(/\s/g,'').indexOf(filter.toLowerCase().replace(/\s/g,'')) >= 0)
		};

		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


	actions = [
		{name: 'Editar', action: this.editProductDialog.bind(this)},
		{name: 'Eliminar', action: this.deleteProductDialog.bind(this)}
	];


}
