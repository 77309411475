
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6" ngClass.xs="mat-elevation-z0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em">

			<h4>Clientes</h4>

			<div class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="1em">

				<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline" [color]="xs ? 'default': 'primary'">
					<mat-label>BUSCAR</mat-label>
					<input matInput maxlength="100" autocomplete="off" #searchInput (keyup)="applyFilter($event.target.value)">
					<app-icon icon="search_primary" matSuffix></app-icon>
				</mat-form-field>

				<button mat-flat-button [color]="xs ? 'default' : 'primary'" (click)="newUserDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">NUEVO CLIENTE</button>

			</div>


			<br *ngIf="loading" />


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay clientes registrados
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort [dataSource]="dataSource" class="full-width" [trackBy]="trackByFn" multiTemplateDataRows>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
					<td mat-cell *matCellDef="let user">
						{{user.name}}
					</td>
				</ng-container>

				<ng-container matColumnDef="username">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
					<td mat-cell *matCellDef="let user">
						{{user.username}}
					</td>
				</ng-container>

				<ng-container matColumnDef="code">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Código de cliente</th>
					<td mat-cell *matCellDef="let user">
						{{user.code}}
					</td>
				</ng-container>


				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let user" class="last-column">

						<button mat-icon-button [matMenuTriggerFor]="actionsMenu" (click)="$event.stopPropagation()">
							<mat-icon>more_vert</mat-icon>
						</button>

						<mat-menu #actionsMenu="matMenu">

							<button mat-menu-item (click)="editUserDialog(user)">
								<mat-icon>edit</mat-icon>
								<span>Editar</span>
							</button>

<!-- 							<button mat-menu-item (click)="deleteUserDialog(user)">
								<mat-icon>delete</mat-icon>
								<span>Eliminar</span>
							</button> -->

						</mat-menu>
					</td>
				</ng-container>



				<ng-container matColumnDef="expanded">
					<td mat-cell *matCellDef="let user" [attr.colspan]="displayedColumns.length">
						<div class="element-detail" [@expand]="user == expandedElement ? 'expanded' : 'collapsed'" fxLayout="column">

							<br/>
							
							<p><strong>Usuario: </strong>{{user.username}}</p>
							<p><strong>Código de cliente: </strong>{{user.code}}</p>
							<p><strong>Email: </strong>{{user.email}}</p>
							<p><strong>Teléfono: </strong>{{user.phone}}</p>
							<p><strong>Dirección: </strong>{{user.address}}</p>

						</div>
					</td>
				</ng-container>



				<tr fxHide.xs mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let user; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === user" (click)="expandedElement = expandedElement === user || !xs ? null : user"></tr>
				<tr mat-row *matRowDef="let user; columns: ['expanded']" class="detail-row"></tr>

			</table>

			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>
			
		</mat-card>

	</div>


	<span class="footer">©2023. Multi. Reservados Todos los Derechos.</span>

</div>