import { Component, OnInit } from '@angular/core';
import { ActivationEnd } from '@angular/router';
import { RouterService } from '../../services/router.service';
import { ApiService } from '../../services/api.service';
import { DatesService } from '../../services/dates.service';
import { DialogService } from '../../services/dialog.service';
import { Tracking } from '../../classes/tracking';
import { MediaObserver } from '@angular/flex-layout';
import { Page } from '../../adapters/page';



@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

/* -------------------- Variables para el tracking de punto a punto -------------------- */
	deliveryProcessBar:any;
	routeProcessBar:any;
	dispatchProcessBar:any;
	invoiceProcessBar:any;
	documentTrackingV: boolean = false;
/* ---------------------------------------------- . --------------------------------------------- */

	year : number = (new Date()).getFullYear();

	order : string;

	tracking : Tracking;

	messages : {mensaje: string; fecha: string;}[] = [];

	get loading() : boolean {return this.tracking == null;}

	progress : number = 30;

	// *** Variables para guardar el estado del tracking  ***
	delivery : number = 0;
	dispatch : number = 0;
	route : number = 0;
	invoiced : number = 0;
	dispatched : number = 0;
	requested : number = 0;
	

	constructor(private routerService: RouterService,
				public datesService: DatesService,
				private dialogService: DialogService,
				private api: ApiService,
				private media: MediaObserver,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd)
			{
				this.order = event.snapshot.url[event.snapshot.url.length - 1].path;

				this.api.tracking(this.order).then(response => {

					this.tracking = response.tracking;
					this.messages = response.mensajes;

					// /* Variables para guardar el progreso del tracking */
			
					this.dispatch = this.tracking.linea.despacho.porcentaje;
					this.delivery = this.tracking.linea.entrega.porcentaje;
					this.invoiced = this.tracking.linea.facturado.porcentaje;
					this.route = this.tracking.linea.ruta.porcentaje;


				});
			}
		});
	}

	get router() {return this.routerService.router;}

	get xs() : boolean {
		return this.media.isActive('xs');
	}

	stringToDate(string: string) : Date {

		const doo = new Date(string);

		return new Date( doo.getTime() - doo.getTimezoneOffset() * -60000 );
	}

	ngOnInit() {

	}

	getDocuments(field: string) {


		var docs : {name: string, url: string}[] = [];

		if (field == 'despacho')
		{
			for (var despacho of this.tracking.despacho.documentos || [])
			{
				docs.push({
					name: despacho.numero,
					url: despacho.url
				});
			}
		}
		else if(field == 'pedido'){
			docs.push({ 
				name: 'Cotización',
				url: 'https://gc.multigroup.com/sales/pedidos/cotizacion/'+this.tracking.cotizacion.id+'/pdfapp/'
			});
		}
		else if (field == 'factura')
		{
			for (var factura of this.tracking.factura.documentos || [])
			{
				docs.push({
					name: factura.numero,
					url: 'https://gc.multigroup.com/'+factura.url
				});
			}
		}
		else if (field == 'adicionales')
		{
			for (var adicional of this.tracking.adicionales.documentos || [])
			{
				docs.push({
					name: adicional.numero,
					url: adicional.url
				});
			}
		}


		if (docs.length > 0)
		{
			this.dialogService.openDialog({
				type: 'documents',
				object: {docs: docs}
			});
		}
	}

}
