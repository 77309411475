import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Purchase, CommercialPurchase } from '../classes/purchase';
import { Company } from '../classes/company';
import { Shopping } from '../classes/shopping';
import { TrackingResponse } from '../classes/tracking';
import { AuthService } from './auth.service';
import { DatesService } from './dates.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

	token : string = "b6dd0e310fcdb00f5d4c6377fb5fa3041171ab42";

	customer: string;

	constructor(private http: HttpClient,
				private datesService: DatesService,
				private authService: AuthService) {

		this.authService.userBS.subscribe(user => {

			if (user) this.customer = user.code;
		});
	}


	get header() : any {

		const header = {
			headers: new HttpHeaders().set('Authorization', 'Token '+this.token)
		};

		return header;
	}


	purchases(year, month) : Promise<Purchase[]> {

		var promise = new Promise<Purchase[]>((resolve, reject) => {

			this.company(this.authService.user.code).then(company => {

				var url = 'https://gc.multigroup.com/baas/v1/pedidos/tracking/finaciero/?inicio='+this.begin(year,month)+'&final='+this.end(year,month)+'&empresas='+this.companiesParam(company);
				this.http.get<any>(url, this.header).subscribe((json: any) => {

					if (json.error)
					{
						reject();
					}
					else
					{
						resolve(json.pedidos);
					}
				},
				(error: any) => {

					reject(error);
				});

			}).catch(reason => {

				reject();
			});
		});

		return promise;
	}


	commercial(year, month) : Promise<CommercialPurchase[]> {

		var promise = new Promise<CommercialPurchase[]>((resolve, reject) => {
	
			this.company(this.authService.user.code).then(company => {

				var url = 'https://gc.multigroup.com/baas/v1/pedidos/tracking/comercial/?inicio='+this.begin(year,month)+'&final='+this.end(year,month)+'&empresas='+this.companiesParam(company);

				this.http.get<any>(url, this.header).subscribe((json: any) => {

					if (json.error)
					{
						reject();						
					}
					else
					{
						resolve(json.pedidos);						
					}
				},
				(error: any) => {

					reject(error);
				});

			}).catch(reason => {

				reject();
			});
		});

		return promise;
	}



	tracking(order: string) : Promise<TrackingResponse> {

		var promise = new Promise<TrackingResponse>((resolve, reject) => {
			var url : string = 'https://gc.multigroup.com/restapi/v1/sales/tracking/?pedido='+order;

			this.http.get<any>(url, this.header).subscribe((json: any) => {				

				if (json.message)
				{
					reject();
				}
				else if (json.tracking)
				{
					resolve(json);
				}
				else
				{
					reject();
				}
			},
			(error: any) => {

				reject(error);
			});
		});

		return promise;
	}



	company(code: string) : Promise<Company> {

		var promise = new Promise<Company>((resolve, reject) => {

			var url : string = 'https://gc.multigroup.com/baas/v1/cliente/detalle/?codigo='+code;

			this.http.get<any>(url, this.header).subscribe((json : any) => {


				if (json.message)
				{
					reject();
				}
				else if (json.data && json.data.length > 0)
				{
					resolve(json.data[0]);
				}
				else
				{
					reject();
				}
			},
			(error: any) => {

				reject(error);
			});
		});

		return promise;
	}


	shopping(code?: string) : Promise<Shopping> {

		if (code)
		{
			return this._shopping(code);
		}
		else
		{
			var promise = new Promise<Shopping>((resolve, reject) => {

				this.company(this.authService.user.code).then(company => {

					if (company)
					{
						this._shopping(company.id).then(shopping => {

							resolve(shopping);

						}).catch(reason => {
							reject();
						});
					}
					else
					{
						reject();
					}

				}).catch(reason => {

					reject();
				});
			});

			return promise;
		}
	}

	_shopping(code: string) : Promise<Shopping> {

		var promise = new Promise<Shopping>((resolve, reject) => {


			var url : string = 'https://gc.multigroup.com/baas/v1/cliente/informacion/?empresa='+code;

			this.http.get<any>(url, this.header).subscribe((json : any) => {

				console.log(json);


				if (json.error)
				{
					reject();
				}
				else if (json.data)
				{
					resolve(json.data);
				}
				else
				{
					reject();
				}
			},
			(error: any) => {

				console.log(error);

				reject(error);
			});
		});

		return promise;
	}


	begin(year, month) : string {

		var date = new Date();

		date.setFullYear(year);
		date.setMonth(month - 1);

		const begin = this.stringDate(this.datesService.getFirstMonthDay(date));

		return begin;
	}

	end(year, month) : string {

		var date = new Date();

		date.setFullYear(year);
		date.setMonth(month - 1);

		const end = this.stringDate(this.datesService.getLastMonthDay(date));

		return end;
	}

	companiesParam(company: Company) : string {

		var companies = '';

		if (this.authService.user.admin || this.authService.user.role == 'admin')
		{
			companies += company.id;

			for (var _company of company.subempresas)
			{
				companies += ',%20'+_company.id;
			}
		}
		else
		{
			for (var _companyID of this.authService.user.companies || [])
			{
				companies += (companies == '' ? _companyID : ',%20'+_companyID);
			}
		}

		return companies;
	}


	stringDate(date: Date) : string {

		return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
	}
}