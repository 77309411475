import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserData, User } from '../../classes/user';
import { Company } from '../../classes/company';
import { ValidatorsService } from '../../services/validators.service';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import { UsersService } from '../../services/users.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {

	@Input('user') user : User;

	@Input('confirm') confirm : Function;
	@Input('close') close : Function;
	@Input('errorMessage') errorMessage : string;

	@Input() loading : boolean;
	@Output() loadingChange = new EventEmitter<boolean>();

	setLoading(value: boolean) {
		this.loading = value;
		this.loadingChange.emit(this.loading);
	}


	saving : boolean = false;


	companies : Company[] = [];

	modules : {id: string, name: string}[] = [
		{id: 'purchases', name: 'Seguimiento de compras'},
		{id: 'company', name: 'Información del negocio'},
		{id: 'products', name: 'Catálogo de productos'},
		{id: 'shopping', name: 'Compras'},
		{id: 'account', name: 'Estado de cuenta'},
		{id: 'invoices', name: 'Administrar facturas y pagos'},
		{id: 'orders', name: 'Órdenes de pago'},
		{id: 'plans', name: 'Planes de pago y extrafinanciamiento'},
		{id: 'credits', name: 'Gestiones crediticias'},
	];


	form : FormGroup;

	constructor(private formBuilder: FormBuilder,
				private validatorsService: ValidatorsService,
				private usersService: UsersService,
				private snackbar: SnackbarService,
				private api: ApiService,
				private dialogService: DialogService,
				private authService: AuthService) {

	}

	ngOnInit() {

		this.api.company(this.authService.user.code).then(company => {

			if (company)
			{
				this.companies.push(company);

				for (var subcompany of (company.subempresas || []))
				{
					this.companies.push(subcompany);
				}
			}
		})

		this.form = this.formBuilder.group({
			companies: [this.user.companies || [], [Validators.required]],
			modules: [this.user.modules || [], [Validators.required, Validators.minLength(1)]]
		});
	}


	save() {

		if (this.form.invalid)
		{
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsTouched();
			});
			
			return;
		}

		this.saving = true;


		const _user = {...this.user};

		_user.companies = this.form.get('companies').value;
		_user.modules = this.form.get('modules').value;
		_user.active = true;

		const active : boolean = this.user.active;

		this.usersService.set(_user, true).then(result => {

			this.user = _user;

			this.snackbar.show(active ? "Usuario guardado" : "Usuario habilitado");
			this.dialogService.closeDialog();

		}).catch(() => {

			this.snackbar.show(active ? "Error al guardar usuario" : "Error al habilitar usuario");
			this.saving = false;
		});
	}
}
