import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { AuthGuard } from './guards/auth.guard';
import { AccountComponent } from './home/account/account.component';
import { ClaimComponent } from './home/claim/claim.component';
import { ComingSoonComponent } from './home/coming-soon/coming-soon.component';
import { CustomersComponent } from './home/customers/customers.component';
import { FinancesComponent } from './home/finances/finances.component';
import { HomeLayoutComponent } from './home/home-layout/home-layout.component';
import { HomeComponent } from './home/home.component';
import { InvoiceComponent } from './home/invoice/invoice.component';
import { InvoicesComponent } from './home/invoices/invoices.component';
import { OffersComponent } from './home/offers/offers.component';
import { OrderComponent } from './home/order/order.component';
import { OrdersComponent } from './home/orders/orders.component';
import { ProductsComponent } from './home/products/products.component';
import { PurchaseComponent } from './home/purchase/purchase.component';
import { PurchasesComponent } from './home/purchases/purchases.component';
import { ShoppingComponent } from './home/shopping/shopping.component';
import { UsersComponent } from './home/users/users.component';
import { LoginComponent } from './login/login.component';
// import { LoginComponent } from './newlogin/login.component';
import { MessageComponent } from './message/message.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { PageOne } from './pageone/pageone.component';
import { PageTwo } from './pagetwo/pagetwo.component';
import { PageThree } from './pagethree/pagethree.component';
import { PageFour } from './pagefour/pagefour.component';
import { SalesteamComponent } from './salesteam/salesteam.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { UserformComponent } from './userform/userform.component';
const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    //canActivate: [AuthGuard],
    //data: { customer: true },
  },
  {
    path: '', // {1}
    component: HomeLayoutComponent,
    //canActivate: [AuthGuard], // {2}
    children: [
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: { customer: true },
      },
      {
        path: 'purchases',
        component: PurchasesComponent,
        canActivate: [AuthGuard],
        data: { module: 'purchases', customer: true },
      },
      {
        path: 'purchases/:item',
        component: PurchaseComponent,
        canActivate: [AuthGuard],
        data: { module: 'purchases', customer: true },
      },
      {
        path: 'claim/:item',
        component: ClaimComponent,
        canActivate: [AuthGuard],
        data: { module: 'purchases', customer: true },
      },
      {
        path: 'products',
        component: ProductsComponent,
        canActivate: [AuthGuard],
        data: { module: 'products', customer: true },
      },
      {
        path: 'company',
        component: OffersComponent,
        canActivate: [AuthGuard],
        data: { module: 'company', customer: true },
      },
      {
        path: 'finances',
        component: FinancesComponent,
        canActivate: [AuthGuard],
        data: { customer: true },
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard],
        data: { module: 'account', customer: true },
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [AuthGuard],
        data: { module: 'invoices', customer: true },
      },
      {
        path: 'invoices/:item',
        component: InvoiceComponent,
        canActivate: [AuthGuard],
        data: { module: 'invoices', customer: true },
      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [AuthGuard],
        data: { module: 'orders', customer: true },
      },
      {
        path: 'orders/:item',
        component: OrderComponent,
        canActivate: [AuthGuard],
        data: { module: 'orders', customer: true },
      },
      {
        path: 'shopping',
        component: ShoppingComponent,
        canActivate: [AuthGuard],
        data: { module: 'shopping', customer: true },
      },
      {
        path: 'plans',
        component: ComingSoonComponent,
        canActivate: [AuthGuard],
        data: { module: 'plans', customer: true },
      },
      {
        path: 'credits',
        component: ComingSoonComponent,
        canActivate: [AuthGuard],
        data: { module: 'credits', customer: true },
      },
      {
        path: 'admin',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: { admin: true },
      },
      {
        path: 'admin/customers',
        component: CustomersComponent,
        canActivate: [AuthGuard],
        data: { admin: true },
      },
      {
        path: 'admin/products',
        component: ProductsComponent,
        canActivate: [AuthGuard],
        data: { admin: true },
      },
      {
        path: 'admin/offers',
        component: OffersComponent,
        canActivate: [AuthGuard],
        data: { admin: true },
      },
      {
        path: 'admin/orders',
        component: OrdersComponent,
        canActivate: [AuthGuard],
        data: { admin: true },
      },
      {
        path: 'admin/orders/:item',
        component: OrderComponent,
        canActivate: [AuthGuard],
        data: { admin: true },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        data: { customer: true },
      },
      {
        path: 'admin/profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        data: { admin: true },
      },
      {
        path: 'message',
        component: MessageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'fb_signup',
    component: SignupComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'home-new',
    component: PageOne,
  },
  {
    path: 'product-new',
    component: PageTwo,
  },
  {
    path: 'purchase-new',
    component: PageThree,
  },
  {
    path: 'purchase-new-two',
    component: PageFour,
  },
  {
    path: 'sales-team',
    component: SalesteamComponent,
  },
  {
    path: 'about-us',
    component: AboutusComponent,
  },
  {
    path: 'user-form',
    component: UserformComponent,
  },
  
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
