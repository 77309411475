import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserData, User } from 'src/app/classes/user';
import { DatabaseService } from 'src/app/services/database.service';
import { StorageService } from 'src/app/services/storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UsersService {


	customers : BehaviorSubject<User[]> = new BehaviorSubject(null);


	constructor(private database: DatabaseService,
				private firebaseService: FirebaseService,
				private snackbar: SnackbarService,
				private storageService: StorageService,
				private http: HttpClient) {


		this.database.array<UserData>('users', 'subscribe', null, {
			field: 'role', op: '==', value: 'admin'
		}).subscribe(result => {

			if (result)
				this.customers.next(result);
		});
	}



	users(code: string) : BehaviorSubject<User[]> {

		const bs = new BehaviorSubject<User[]>(null);

		this.database.array<UserData>('users', 'subscribe', null, {
			field: 'code', op: '==', value: code
		}).subscribe(result => {

			if (result)
			{
				bs.next(result);
			}
		});

		return bs;
	}




	get(userID: string) : Promise<User> {

		return this.database.get<UserData>('users', userID);
	}

	add(user: UserData, password: string, silent?: boolean) : Promise<any> {


		var promise = new Promise<any>((resolve, reject) => {


			const url = 'https://us-central1-multigroup-sa.cloudfunctions.net/createUser?user='+user.username+'&password='+password;

			this.http.get(url, {responseType: 'json'}).subscribe((res : any) => {

				if (res.ok)
				{
					const userID = res.uid;

					return this.database.set('users', userID, {id: userID, ...user}, silent ? null : {
						success: 'Usuario creado',
						error: 'Error al crear usuario'
					}).then(result => {
						resolve(result);
					}).catch(reason => {
						reject(reason);
					});
				}
				else
				{
					this.snackbar.show(res.error);
					reject(res.error);
				}

			}, err => {
				this.snackbar.show("Error al crear usuario");
				reject(err);
			});
		});

		return promise;
	}


	set(user: User, silent?: boolean) : Promise<any> {

		var userData : UserData = {...user};

		return this.database.set('users', user.id, userData, silent ? null : {
			success: 'Usuario guardado',
			error: 'Error al guardar usuario'
		});
	}


	setUserMessagingToken(userID: string, token: string) {

		this.database.update('users', userID, {
			token: token
		});
	}


	getImageUrl(user: User) : Promise<string> {

		var promise = new Promise<string>((resolve, reject) => {

			if (user.profileImagePath)
			{
				var subscription = this.storageService.getUrl(user.profileImagePath).then(url => {

					if (url)
						resolve(url);
				});
			}
			else if (user.profileImageUrl)
			{
				resolve(user.profileImageUrl);
			}
			else
			{
				resolve(null);
			}
		});

		return promise;
	}
}
