<div class="main-container" [class.example-is-mobile]="mobileQuery.matches">




	<!-- [opened]="sidebarToggleState" -->
	<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 1 : 0">
		<mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
			fixedTopGap="56">


			<div class="menu-inner">
				<div class="menu-top">

					<a routerLink="" class="menu-logo">
						<img src="/assets/images/new-logo.svg" />
					</a>

					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />PANEL DE CONTROL</a>
						</mat-nav-list>
						<mat-nav-list class="submenu-wrap">
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTION FINANCIERA</a>
						</mat-nav-list>

						<div class="submenu">
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ESTADO DE CUENTA</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />FACTURAS Y PAGOS</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ORDENES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />PLANES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTIONES CREDITICIAS</a>
							</mat-nav-list>
						</div>

						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />COMPRAS</a>
						</mat-nav-list>
					</div>

				</div>

				<div class="menu-bottom">
					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MUILTIGROUP.COM</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MI CUENTA</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />CERRAR SESION</a>
						</mat-nav-list>
					</div>
				</div>
			</div>
		</mat-sidenav>

		<mat-sidenav-content class="left-sidenav-content">
			<button mat-icon-button (click)="snav.toggle()" fxLayout.gt-sm="none"
				class="hamburger-icon"><mat-icon>menu</mat-icon></button>
			



			<!-- puechase -->
			<mat-card class="custum-card">

			
				<h1>SEGUIMIENTO DE COMPRAS</h1>

				<div class="purchase-table">
					<div class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px" fxLayout.xs="column" fxLayout.sm="column">
						<div class="data-input">
							<input type="date" />
						</div>
						<div class="button-res" fxLayout="row nowrap" fxLayoutGap="12px" fxLayout.xs="column" fxLayout.sm="column">
							<button mat-button class="home-btn bg-red" color="white">
								<img src="/assets/images/plus-sign.png" />
								<span>ULTIMO MES</span>
							</button>
							<button mat-button class="home-btn" color="white">
								<img src="/assets/images/refresh-icon.svg" />
								<span>ULTIMOS 8 DIAS</span>
							</button>
						</div>

						
					</div>
					<div class="custum-table">
						<table mat-table [dataSource]="dataSource" class="mobile-table">
						
							
							<ng-container matColumnDef="pedido">
								<th mat-header-cell *matHeaderCellDef> Pedido </th>
								<td mat-cell *matCellDef="let element" class="listing-no"> <a routerLink=""> {{element.pedido}} </a></td>
							</ng-container>
						
						
							<ng-container matColumnDef="empresa">
								<th mat-header-cell *matHeaderCellDef> Empresa </th>
								<td mat-cell *matCellDef="let element"> {{element.empresa}} </td>
							</ng-container>
						
							
							<ng-container matColumnDef="fecha">
								<th mat-header-cell *matHeaderCellDef> Fecha </th>
								<td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
							</ng-container>
						
						
							<ng-container matColumnDef="estado">
								<th mat-header-cell *matHeaderCellDef> Estado </th>
								<td mat-cell *matCellDef="let element"> <button mat-button color="primary">{{element.estado}}</button> </td>
							</ng-container>

							<ng-container matColumnDef="proximoestado">
								<th mat-header-cell *matHeaderCellDef> Proximo Estado </th>
								<td mat-cell *matCellDef="let element"> <button mat-button color="success">{{element.pedido}}</button> </td>
							</ng-container>

							<ng-container matColumnDef="action">
								<th mat-header-cell *matHeaderCellDef> </th>
								<td mat-cell *matCellDef="let element"> 
									
									<app-icon icon="search_primary" size="16" class="clickable" ></app-icon>
									
							</ng-container>
						
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>

					
					</div>
				</div>
			</mat-card>


			
			<div class="copyright-box">
				<p>© 2023. Multi. Reservados Todos los Derechos.</p>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>