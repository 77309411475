<div class="main-container" [class.example-is-mobile]="mobileQuery.matches">
  <!-- [opened]="sidebarToggleState" -->
  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 1 : 0"
  >
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
    >
      <div class="menu-inner">
        <div class="menu-top">
          <a routerLink="" class="menu-logo">
            <img src="/assets/images/new-logo.svg" />
          </a>

          <div class="menu-links">
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />PANEL DE CONTROL</a
              >
            </mat-nav-list>
            <mat-nav-list class="submenu-wrap">
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />GESTION
                FINANCIERA</a
              >
            </mat-nav-list>

            <div class="submenu">
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />ESTADO DE
                  CUENTA</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />FACTURAS Y
                  PAGOS</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />ORDENES DE
                  PAGO</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />PLANES DE PAGO</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a mat-list-item
                  ><img src="/assets/images/multi_arrow.png" />GESTIONES
                  CREDITICIAS</a
                >
              </mat-nav-list>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <img
                      class="panel-icon"
                      src="/assets/images/multi_arrow.png"
                    />
                    This is the expansion title
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-body">
                  <mat-nav-list>
                    <a mat-list-item
                      ><img src="/assets/images/multi_arrow.png" />PLANES DE
                      PAGO</a
                    >
                  </mat-nav-list>
                  <mat-nav-list>
                    <a mat-list-item
                      ><img src="/assets/images/multi_arrow.png" />GESTIONES
                      CREDITICIAS</a
                    >
                  </mat-nav-list>
                </div>
              </mat-expansion-panel>
            </div>

            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />COMPRAS</a
              >
            </mat-nav-list>
          </div>
        </div>

        <div class="menu-bottom">
          <div class="menu-links">
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />MUILTIGROUP.COM</a
              >
            </mat-nav-list>
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />MI CUENTA</a
              >
            </mat-nav-list>
            <mat-nav-list>
              <a mat-list-item
                ><img src="/assets/images/multi_arrow.png" />CERRAR SESION</a
              >
            </mat-nav-list>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="left-sidenav-content">
      <button
        mat-icon-button
        (click)="snav.toggle()"
        fxLayout.gt-sm="none"
        class="hamburger-icon"
      >
        <mat-icon>menu</mat-icon>
      </button>

      <a href="#" class="whatsapp-icon-wrap">
        <img
          class="whatsapp-stick-icon"
          src="../../assets/images/whatsapp.png"
          alt=""
          srcset=""
        />
      </a>

      <br />
      <mat-card class="custum-card">
        <h1>PRODUCTOS</h1>
        <div class="edit-link" fxLayout="row" fxLayoutAlign="end">
          <a [routerLink]="">Regresar</a>
        </div>

        <div class="product-box-wrap" fxLayout="row">
          <a [routerLink]="" class="product-box">
            <div
              class="product-img"
              style="background-image: url('../../assets/images/p1.png')"
            >
              <div class="product-layer"></div>
              <div class="product-info">
                <p>TITULO LINEA</p>
                <span>Ver Productos</span>
              </div>
            </div>
          </a>
          <a [routerLink]="" class="product-box">
            <div
              class="product-img"
              style="background-image: url('../../assets/images/p2.png')"
            >
              <div class="product-layer"></div>
              <div class="product-info">
                <p>TITULO LINEA</p>
                <span>Ver Productos</span>
              </div>
            </div>
          </a>
          <a [routerLink]="" class="product-box">
            <div
              class="product-img"
              style="background-image: url('../../assets/images/p3.png')"
            >
              <div class="product-layer"></div>
              <div class="product-info">
                <p>TITULO LINEA</p>
                <span>Ver Productos</span>
              </div>
            </div>
          </a>
          <a [routerLink]="" class="product-box">
            <div
              class="product-img"
              style="background-image: url('../../assets/images/p4.png')"
            >
              <div class="product-layer"></div>
              <div class="product-info">
                <p>TITULO LINEA</p>
                <span>Ver Productos</span>
              </div>
            </div>
          </a>
          <a [routerLink]="" class="product-box">
            <div
              class="product-img"
              style="background-image: url('../../assets/images/p5.png')"
            >
              <div class="product-layer"></div>
              <div class="product-info">
                <p>TITULO LINEA</p>
                <span>Ver Productos</span>
              </div>
            </div>
          </a>
          <a [routerLink]="" class="product-box">
            <div
              class="product-img"
              style="background-image: url('../../assets/images/p6.png')"
            >
              <div class="product-layer"></div>
              <div class="product-info">
                <p>TITULO LINEA</p>
                <span>Ver Productos</span>
              </div>
            </div>
          </a>
        </div>
      </mat-card>

      <div class="copyright-box">
        <p>© 2023. Multi. Reservados Todos los Derechos.</p>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
