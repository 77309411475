
<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6" ngClass.xs="full-width mat-elevation-z0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" fxLayoutGap.xs="0em">

			<div class="profile-header" fxLayout="column" fxLayoutAlign="center center">

				<div class="photo-box" fxLayout="column" fxLayoutAlign="center center">

					<div class="photo" fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'background-image' : (!uploading && photoUrl != '' && photoUrl != null ? 'url('+photoUrl+')' : 'none')}">
					</div>

					<mat-spinner *ngIf="uploading" class="spinner" diameter="40" color="primary"></mat-spinner>

					<button *ngIf="!uploading" mat-icon-button (click)="imageInputClick()" fxLayout="column" fxLayoutAlign="center center">
						<mat-icon>edit</mat-icon>
					</button>

					<input #imageInput (change)="uploadImage($event)" class="hidden" hidden="true" type="file" accept=".png,.jpg" />
				</div>
			</div>



			<div fxFlex class="profile-content" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="1em" fxLayout.xs="column" fxLayoutAlign.xs="start center">

				<div ngClass.xs="profile-details" fxFlex="40" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em">
					
					<div fxLayout="column" fxLayoutGap="0.15em">
						<strong>NOMBRE</strong>
						<p>{{authService.user?.name || '-'}}</p>
					</div>

					<div fxLayout="column" fxLayoutGap="0.15em">
						<strong>USUARIO</strong>
						<p>{{authService.user?.username || '-'}}</p>
					</div>

					<div fxLayout="column" fxLayoutGap="0.15em">
						<strong>EMAIL</strong>
						<p>{{authService.user?.email || '-'}}</p>
					</div>

					<div fxLayout="column" fxLayoutGap="0.15em">
						<strong>TELÉFONO</strong>
						<p>{{authService.user?.phone || '-'}}</p>
					</div>

					<div fxLayout="column" fxLayoutGap="0.15em">
						<strong>DIRECCIÓN</strong>
						<p>{{authService.user?.address || '-'}}</p>
					</div>

					<div fxLayout="column" fxLayoutGap="0.15em">
						<strong>CÓDIGO DE CLIENTE</strong>
						<p>{{authService.user?.code || '-'}}</p>
					</div>

				</div>

				<div *ngIf="user?.username" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="1em">
					<button mat-button color="primary" (click)="resetPasswordDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">Cambiar contraseña</button>
					<button mat-flat-button color="primary" (click)="editProfileDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">EDITAR PERFIL</button>
				</div>

			</div>
			

		</mat-card>

	</div>


	<span class="footer" ngClass.xs="primary">©2023. Multi. Reservados Todos los Derechos.</span>

</div>