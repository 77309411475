<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">





	<div fxFlex ngClass.gt-xs="content" ngClass.xs="full-width" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6" ngClass.xs="mat-elevation-z0" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em" fxLayoutGap.xs="0em">

			<h4>Productos</h4>

			<div class="actions-bar" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="1em">

				<mat-form-field ngClass.gt-xs="primary" ngClass.xs="full-width" appearance="outline" [color]="xs ? 'default': 'primary'">
					<mat-label>BUSCAR</mat-label>
					<input matInput maxlength="100" autocomplete="off" #searchInput (keyup)="applyFilter($event.target.value)">
					<app-icon icon="search_primary" matSuffix></app-icon>
				</mat-form-field>

				<button *ngIf="admin" mat-flat-button [color]="xs ? 'default' : 'primary'" (click)="newProductDialog()"><img class="multi_arrow" [src]="'assets/images/multi_arrow.png'">NUEVO PRODUCTO</button>

			</div>


			<p class="empty-text" *ngIf="!loading && dataSource.data?.length < 1">
				No hay productos cargados
			</p>

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<table [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" mat-table matSort [dataSource]="dataSource" class="full-width" [trackBy]="trackByFn">

				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
					<td mat-cell *matCellDef="let product">
						{{product.title}}
					</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de inicio</th>
					<td mat-cell *matCellDef="let product">
						{{datesService.formattedDate(product.date) || '-'}}
					</td>
				</ng-container>

				<ng-container matColumnDef="expiration">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Vencimiento</th>
					<td mat-cell *matCellDef="let product">
						{{datesService.formattedDate(product.expiration) || '-'}}
					</td>
				</ng-container>


				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let product" class="last-column">

						<button *ngIf="admin" mat-icon-button [matMenuTriggerFor]="actionsMenu" (click)="$event.stopPropagation()">
							<mat-icon>more_vert</mat-icon>
						</button>

						<mat-menu #actionsMenu="matMenu">

							<button mat-menu-item (click)="editProductDialog(product)">
								<mat-icon>edit</mat-icon>
								<span>Editar</span>
							</button>

							<button mat-menu-item (click)="deleteProductDialog(product)">
								<mat-icon>delete</mat-icon>
								<span>Eliminar</span>
							</button>

						</mat-menu>
					</td>
				</ng-container>



				<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let product; columns: displayedColumns;" class="clickable" (click)="openPreviewDialog(product)"></tr>

			</table>

			<mat-paginator fxFlexAlign="end" [ngClass]="{'hidden': loading || dataSource.data?.length < 1}" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="pageSize" [pageIndex]="page"></mat-paginator>
			
		</mat-card>

	</div>


	<span class="footer">©2023. Multi. Reservados Todos los Derechos.</span>

</div>