import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { DatesService } from '../../services/dates.service';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { OrdersService } from '../../services/orders.service';
import { NumbersService } from '../../services/numbers.service';
import { SessionService } from '../../services/session.service';
import { RouterService } from '../../services/router.service';
import { DatabaseService } from '../../services/database.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatInput } from '@angular/material/input';
import { MediaObserver } from '@angular/flex-layout';
import { Purchase } from '../../classes/purchase';
import { Order, OrderData } from '../../classes/order';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Page } from '../../adapters/page';
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('expand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class InvoicesComponent implements OnInit {

	year : number = (new Date()).getFullYear();

	loading : boolean = true;

	displayedColumns: string[];
	dataSource: MatTableDataSource<Purchase> = new MatTableDataSource([]);

	selection = new SelectionModel<Purchase>(true, []);

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;

	mediaSubscription : Subscription;

	expandedElement : Purchase;


	@ViewChild('dp', {static: true}) datepicker: MatDatepicker<Date>;
	@ViewChild('datepickerInput', {static: true}) datepickerInput : ElementRef;


	page : number = 0;
	pageSize : number = 10;


	monthDate : Date = new Date();


	filter : 'month' | '8days' = 'month';

	form : FormGroup
	constructor(public api: ApiService,
				private routerService: RouterService,
				private dialogService: DialogService,
				private formBuilder: FormBuilder,
				public authService: AuthService,
				public numbersService: NumbersService,
				public datesService: DatesService,
				private snackbar: SnackbarService,
				public ordersService: OrdersService,
				private database: DatabaseService,
				private changeDetectorRef: ChangeDetectorRef,
				public media: MediaObserver,
				private sessionService: SessionService,
				private _page: Page) {

		this._page.actionBarHidden = true;

		this.updateColumns();
		
		this.form = this.formBuilder.group({search: ['', []]});
		
		this.mediaSubscription = this.media.asObservable().subscribe(change => {
			this.updateColumns();
		});

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();
	}


	get router() {return this.routerService.router;}


	get xs() : boolean {
		return this.media.isActive('xs');
	}


	updateColumns() {

		if (this.media.isActive('gt-xs'))
		{
			this.displayedColumns = ['select', 'factura_numero', 'factura_fecha', 'saldo_pendiente', 'status', 'actions'];
		}
		else
		{
			this.displayedColumns = ['select', 'factura_numero', 'actions'];
		}
	}

	loadData() {

		this.ordersService.orders.subscribe(orders => {

			if (orders)
				this.loadPurchases(orders);
		});
	}

	applyFilter(filterValue: string) {

		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	loadPurchases(orders: Order[]) {

		this.updateColumns();

		this.api.purchases(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1).then(result => {

			if (result)
			{
				var lastDate : Date;

				let purchases = result.map(purchase => {

					var _status = purchase.saldo_pendiente <= 0 ? 'paid' : 'unknow';

					for (const order of orders)
					{
						if (!order.paid && order.purchases.indexOf(purchase.factura_numero) >= 0)
						{
							_status = 'pending';
						}
					}
					return {...purchase, orderStatus: _status};
				});

				purchases = purchases.filter(function(pur){
					return pur.orderStatus !== "pending";
				})
				
				for (var purchase of purchases)
				{
					const purchaseDate = this.stringToDate(purchase.factura_fecha);

					if (lastDate == null || lastDate.getTime() < purchaseDate.getTime())
					{
						lastDate = purchaseDate;
					}
				}


				this.dataSource.data = purchases.filter(purchase => {

					if (this.filter == 'month') return true;

					if (lastDate)
					{
						const purchaseDate = this.stringToDate(purchase.factura_fecha);

						return this.datesService.daysBetween(purchaseDate, lastDate) < 8;
					}

					return true;
				});


				this.loading = false;

				if (!this.changeDetectorRef['destroyed'])
					this.changeDetectorRef.markForCheck();
			}

		}).catch(reason => {

			this.loading = false;

			if (!this.changeDetectorRef['destroyed'])
				this.changeDetectorRef.markForCheck();
		});
	}


	stringToDate(string: string) : Date {

		const doo = new Date(string);

		return new Date( doo.getTime() - doo.getTimezoneOffset() * -60000 );
	}



	ngOnInit() {

		if (this.paginator)
		{
			this.paginator._intl.itemsPerPageLabel = "Compras por página:";
			this.paginator._intl.firstPageLabel = "Primera página";
			this.paginator._intl.previousPageLabel = "Página anterior";
			this.paginator._intl.nextPageLabel = "Página siguiente";
			this.paginator._intl.lastPageLabel = "Última página";

			this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {

				if (length == 0 || pageSize == 0) 
				{
					return `0 de ${length}`;
				}

				length = Math.max(length, 0);
				const startIndex = page * pageSize;
				const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
				return `${startIndex + 1} – ${endIndex} de ${length}`;
			};

			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		}

		const monthItem = this.sessionService.getItem('monthDate');

		if (monthItem)
			this.monthDate.setTime(JSON.parse(monthItem).date);


		this.filter = (this.sessionService.getItem('filter') as 'month' | '8days') || 'month';


		this.loadData();

		this.setInputValue();
	}


	ngOnDestroy() {

		if (this.mediaSubscription) this.mediaSubscription.unsubscribe();
	}


	trackByFn(index, item: Purchase) {
		return item.row_id;
	}


	chosenYearHandler(yearDate: Date) {

		this.monthDate.setFullYear(yearDate.getFullYear());
	}

	chosenMonthHandler(monthDate: Date, datepicker: MatDatepicker<Date>) {

		this.monthDate.setMonth(monthDate.getMonth());
		this.monthDate.setFullYear(monthDate.getFullYear());

		this.sessionService.setItem('monthDate', JSON.stringify({date: this.monthDate.getTime()}));

		if (datepicker)
		{
			datepicker.close();
			datepicker.select(this.monthDate);
		}

		this.setInputValue();

		this.loading = true;

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();

		this.loadData();
	}


	setInputValue() {

		const newValue = this.datesService.monthName(this.monthDate) + ' ' + this.monthDate.getFullYear();

		if (this.datepickerInput)
		{
			const input = (this.datepickerInput.nativeElement as MatInput);

			input.value = newValue;
		}
	}


	selectFilter(filter: 'month' | '8days') {

		if (this.loading) return;

		this.filter = filter;

		this.sessionService.setItem('filter', this.filter);

		this.loading = true;

		if (!this.changeDetectorRef['destroyed'])
			this.changeDetectorRef.markForCheck();

		this.loadData();
	}




	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ?
		this.selection.clear() :
		this.dataSource.data.forEach(row => this.selection.select(row));
	}



	generateOrder() {

		this.loading = true;

		this.api.company(this.authService.user.code).then(company => {

			const orderID : string = this.database.createID();

			const order : Order = {
				id: orderID,
				company: company.nombre,
				companyCode: this.authService.user.code,
				purchases: this.selection.selected.map(purchase => {return purchase.factura_numero}),
				purchasesData: this.selection.selected,
				user: this.authService.user.id,
				date: new Date(),
				amount: this.selection.selected.reduce((a, b) => a + (b.saldo_pendiente || 0), 0),
				paid: false
			};

			this.ordersService.add(order).then(result => {

				this.routerService.extensions.navigate(['/orders/'+orderID]);

			}).finally(() => {
				this.loading = false;
			});

		}).catch(reason => {

			this.snackbar.show("Error al generar orden de pago");
			this.loading = false;
		});
	}

}
