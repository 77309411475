





<div class="login">
	<div class="login-left">
		<div class="login-info-left">
			<h1>
				<span class="title">BIENVENIDOS </span>
				<span class="subtitle">PORTAL DE CLIENTES</span>
			</h1>
			<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
				laoreet dolore
				magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper susci
			</p>
		</div>
	</div>
	<div class="login-right">
		<div class="login-form">
			<div class="login-form-inner">
				<div class="logo-box">
					<img src="../../assets/images/multi_red.png" alt="Logo" />
				</div>

				<div class="login-form-box">
					<h2>Login</h2>
					<p>Por favor, ingresa tu usuario y contrasena para poder tener acceso al sistema.</p>
					
						<!-- <mat-form-field class="full-width" appearance="outline" color="accent">
							<mat-label>USUARIO</mat-label>
							<input matInput formControlName="username" maxlength="100" autocomplete="off">
						</mat-form-field>
						
						<mat-form-field class="full-width" appearance="outline" color="accent">
							<mat-label>CONTRASEÑA</mat-label>
							<input matInput type="password" formControlName="password" maxlength="100" autocomplete="off">
						</mat-form-field> -->
						<!-- <form>
						<div class="input-wrap">
							<input type="text" placeholder="Usuario" />
						</div>

						<div class="input-wrap">
							<input type="text" placeholder="Contrasena" />
						</div>

						<div class="input-wrap normal-line">
							<a href="#" class="login-link">Recuperar Contrasena</a>
						</div>

						<div class="input-wrap normal-line">
							<button class="login-btn">
								<img src="../../assets/images/multi_arrow.png" />
								INGRESAR
							</button>
						</div>
					</form> -->
					<mat-spinner *ngIf="loading" color="red" diameter="40"></mat-spinner>
					
					
					<form *ngIf="!loading" class="full-width" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.5em"
						[formGroup]="form" (ngSubmit)="formLogin()" autocomplete="off">

						<div class="input-wrap">
						<mat-form-field class="full-width" appearance="outline">
							
							<input matInput formControlName="username" maxlength="100" autocomplete="off">
							<mat-error *ngIf="form.get('username').hasError('required')">Usuario requerido</mat-error>
						</mat-form-field>
					</div>
					<div class="input-wrap">
						<mat-form-field class="full-width" appearance="outline">
							
							<input matInput type="password" formControlName="password" maxlength="100" autocomplete="off">
							<mat-error *ngIf="form.get('password').hasError('required')">Contraseña requerida</mat-error>
						</mat-form-field>
					
					</div>
						<span ngClass.gt-xs="error" *ngIf="authService.loginStatus == 'Error'">
							{{authService.errorMessage}}
						</span>
						<div class="input-wrap normal-line">
							<a href="#" class="login-link">Recuperar Contrasena</a>
						</div>
						<div class="input-wrap normal-line">
							<button class="login-btn" type="submit
							">
								<img src="../../assets/images/multi_arrow.png" />
								INGRESAR
							</button>
						</div>
					
						<!-- <button mat-flat-button type="submit">INGRESAR</button> -->
					
						<!-- <button mat-button type="button" (click)="resetPasswordDialog()">Recuperar contraseña</button> -->
					
					</form>

					<div class="after-login">
						<h3>NUEVOS USUARIOS</h3>
						<button class="login-btn">
							<img src="../../assets/images/multi_arrow.png" />
							REGISTRO
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>