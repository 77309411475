<div class="home" ngClass.xs="xs" fxLayout="column" fxLayoutAlign="space-between center">


	<!-- <app-navbar class="full-width" bgcolor="primary"></app-navbar> -->


	<div fxFlex ngClass.gt-xs="content" ngClass.xs="tracking-content" fxLayout="column" fxLayoutAlign="start center">

		<mat-card ngClass.gt-xs="mat-elevation-z6 content-card" ngClass.xs="content-card mat-elevation-z0"
			fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="3em">

			<mat-spinner *ngIf="loading" fxFlexAlign="center" color="primary" diameter="40"></mat-spinner>


			<div *ngIf="!loading" class="claim-container" fxLayout="row" fxLayoutAlign="end end">

				<mat-card class="claim clickable mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-between center"
					fxLayoutGap="2em" [routerLink]="'/claim/'+order">

					<div class="claim-text-container" fxLayout="column" fxLayoutAlign="space-between start"
						fxLayoutGap="0.5em">

						<h4>¿Necesitas ayuda?</h4>
						<span>Comunícate con nosotros</span>

					</div>

					<div class="claim-image-container" fxLayout="column" fxLayoutAlign="center center">
						<app-icon [icon]="xs ? 'chat' : 'chat_primary'" size="24"></app-icon>
					</div>

				</mat-card>

			</div>

			<!-- ------------------------------------------------------------------------------------------- -->
			<!--                            INTEGRACION DE TRACKING PUNTO A PUNTO                            -->
			<!-- ------------------------------------------------------------------------------------------- -->

			<div class="card" *ngIf="!loading">
				<h4 class="card-header border-0">
					SEGUIMIENTO DE PEDIDO
				</h4>
				<div class="card-body">



					<div class="accordion mt-3" id="accordionDetail" *ngIf="tracking.detalle_completo">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
									data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
									DETALLE DE TRACKING
								</button>
							</h2>
							<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
								data-bs-parent="#accordionDetail">
								<div class="accordion-body">

									<div class="table-responsive"
										*ngIf="tracking.detalle_completo.length !=0; else withoutData">
										<table class="table table-striped">
											<thead>
												<tr>
													<th scope="col">Descripción de Producto</th>
													<th scope="col" class="text-center">Solicitado</th>
													<th scope="col" class="text-center">Despacho</th>
													<th scope="col" class="text-center">Facturado</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let item of tracking.detalle_completo">
													<td>{{item.descripcion}}</td>
													<td class="text-center">{{item.solicitado | number:'1.2-2'}}</td>
													<td class="text-center">{{item.despacho | number:'1.2-2'}}</td>
													<td class="text-center">{{item.facturado | number:'1.2-2'}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<ng-template #withoutData>
										<h4>Actualmente no posee un detalle de pedido</h4>
									</ng-template>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>

			<!-- ------------------------------ FIN DE TRACKING PUNTO A PUNTO ------------------------------ -->

			<div *ngIf="!loading" class="progress pt-3" fxLayout="row" fxLayoutAlign="center start"
				style="height: 13rem;">



				<div class="step" [ngClass]="{'active': tracking.estados.confirmado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">
					<span class="porcent">{{(tracking.estados.confirmado) ? '100':'0'}}%</span>

					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left hidden"></div>
						<div class="circle"></div>
						<div class="line right"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'confirm'+(tracking.estados.confirmado ? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32">
						</app-icon>
					</div>

					<span style="font-family: open sans; font-size: 10px; font-weight: bold; ">PEDIDO <br> CONFIRMADO</span>

				</div>

				<div class="step" [ngClass]="{'active': tracking.estados.programado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">

					<span class="porcent">{{(tracking.estados.programado ? '100':'0')}}%</span>
					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left"></div>
						<div class="circle"></div>
						<div class="line right"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'calendar'+(tracking.estados.programado? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32"></app-icon>
					</div>

					<span style="font-family: open sans; font-size: 10px; font-weight: bold; ">ASIGNADO <br> PROGRAMADO</span>

				</div>

				<div class="step" [ngClass]="{'active': tracking.estados.cargado || tracking.estados.entregado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">
					<span class="porcent">{{tracking.linea.despacho.porcentaje}}%</span>
					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left"></div>
						<div class="circle"></div>
						<div class="line right"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'products'+(tracking.estados.cargado || tracking.estados.entregado ? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32"></app-icon>
					</div>

					<span style="font-family: open sans; font-size: 10px; font-weight: bold; ">PEDIDO <br /> DESPACHADO</span>

				</div>

				<div class="step" [ngClass]="{'active': tracking.factura.generado}"
					fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5em">
					<span class="porcent">{{tracking.linea.facturado.porcentaje}}%</span>
					<div fxLayout="row" fxLayoutAlign="center center" class="full-width">
						<div class="line left"></div>
						<div class="circle"></div>
						<div class="line right hidden"></div>
					</div>

					<div class="step-image" fxLayout="column" fxLayoutAlign="center center">
						<app-icon
							[icon]="'dispatch'+(tracking.factura.generado ? (xs ? '_primary' : '') : (xs ? '' : '_primary'))"
							size="32"></app-icon>
					</div>

					<span>PEDIDO FACTURADO</span>

				</div>

			</div>


			<div class="card" *ngIf="!loading">
				<div class="card-header border-0">
					DOCUMENTACIÓN
				</div>
				<div class="card-body">

					<div *ngIf="!loading" class="progress files pt-2" fxLayout="row" fxLayoutAlign="center start"
						style="height: 7rem;">

						<div class="step" [ngClass]="{'active': tracking.pedido.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('pedido')">
								<app-icon icon="order" size="32"></app-icon>
							</div>

							<span>PEDIDO</span>

						</div>

						<div class="step" [ngClass]="{'active': tracking.despacho.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('despacho')">
								<app-icon icon="dispatch" size="32"></app-icon>
							</div>

							<span>DESPACHO</span>

						</div>

						<div class="step" [ngClass]="{'active': tracking.factura.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('factura')">
								<app-icon icon="receipt" size="32"></app-icon>
							</div>

							<span>FACTURA</span>

						</div>

						<div class="step" [ngClass]="{'active': tracking.adicionales.generado}" fxLayout="column"
							fxLayoutAlign="start center" fxLayoutGap="0.5em">

							<div class="step-image" fxLayout="column" fxLayoutAlign="center center"
								(click)="getDocuments('adicionales')">
								<app-icon icon="docs" size="32"></app-icon>
							</div>

							<span>DOC. ADICIONALES</span>

						</div>

					</div>
				</div>
			</div>

		</mat-card>

	</div>


	<span fxHide.xs class="footer" ngClass.xs="primary">©2023. Multi. Reservados Todos los Derechos.</span>

</div>