<div class="main-container" [class.example-is-mobile]="mobileQuery.matches">




	<!-- [opened]="sidebarToggleState" -->
	<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 1 : 0">
		<mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
			fixedTopGap="56">


			<div class="menu-inner">
				<div class="menu-top">

					<a routerLink="" class="menu-logo">
						<img src="/assets/images/new-logo.svg" />
					</a>

					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />PANEL DE CONTROL</a>
						</mat-nav-list>
						<mat-nav-list class="submenu-wrap">
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTION FINANCIERA</a>
						</mat-nav-list>

						<div class="submenu">
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ESTADO DE CUENTA</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />FACTURAS Y PAGOS</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />ORDENES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />PLANES DE PAGO</a>
							</mat-nav-list>
							<mat-nav-list>
								<a mat-list-item><img src="/assets/images/multi_arrow.png" />GESTIONES CREDITICIAS</a>
							</mat-nav-list>
						</div>

						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />COMPRAS</a>
						</mat-nav-list>
					</div>

				</div>

				<div class="menu-bottom">
					<div class="menu-links">
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MUILTIGROUP.COM</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />MI CUENTA</a>
						</mat-nav-list>
						<mat-nav-list>
							<a mat-list-item><img src="/assets/images/multi_arrow.png" />CERRAR SESION</a>
						</mat-nav-list>
					</div>
				</div>
			</div>
		</mat-sidenav>

		<mat-sidenav-content class="left-sidenav-content">
			<button mat-icon-button (click)="snav.toggle()" fxLayout.gt-sm="none"
				class="hamburger-icon"><mat-icon>menu</mat-icon></button>
			<mat-card class="custum-card">
				<h1>BIENVENIDO, USUARIO</h1>
				<div class="home-row" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayout.sm="column">
				
						
					<div class="question-box row-font">
						<img src="/assets/images/ques-info.png" />
						NECESITAS AYUDA?
					</div>
					<div class="no-box row-font">
						<img src="/assets/icons/phone-icon.svg" />
						2320-5555
					</div>
					<div>
						<button mat-button class="home-btn bg-red">
							<img src="/assets/images/plus-sign.png"/>
							<span>CREAR TICKET</span>
						</button>
					</div>
					<div>
						<button mat-button class="home-btn">
							<img src="/assets/images/plus-sign.png" />
							<span>HABLAR CON ALGUIEN</span>
						</button>
					</div>
				</div>

				<div class="home-image-box">
					<div class="image-box-inner">
						<div  fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
							<div class="">
								<img src="../../assets/images/left.png" />
							</div>
							<div class="">
								<img src="../../assets/images/right.png" />
							</div>
						</div>
					</div>
				</div>
				
			</mat-card>


			
			<div class="copyright-box">
				<p>© 2023. Multi. Reservados Todos los Derechos.</p>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>