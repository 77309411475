import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { RouterService } from '../services/router.service';
import { ActivationEnd } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { ProfileService } from '../services/profile.service';
import { MediaObserver } from '@angular/flex-layout';
import { Menu } from '../adapters/menu';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthGuard } from '../guards/auth.guard';

export interface HomeSection {
	icon: string;
	text: string;
	path: string;
	module?: string;
	hidden?: boolean;
	disabled?: boolean;
}

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	mobileQuery: MediaQueryList;
	@Input('bgcolor') bgcolor: string = 'default';

	@Input('forceColor') forceColor: boolean = false;

	username: string;

	homePath: string;


	photoUrl: string = "";

	urlLength: number = 10;

	get showBack(): boolean {

		return this.homePath && (this.urlLength > (this.homePath == '/' ? 0 : 1))
	}
	isHome = false;
	private _mobileQueryListener: () => void;
	sections: HomeSection[] = [];
	url='';
	constructor(public authService: AuthService,
		public storageService: StorageService,
		private changeDetectorRef: ChangeDetectorRef,
		private routerService: RouterService,
		private profileService: ProfileService,
		private media: MediaObserver,
		private lmedia: MediaMatcher) {

			
			
		this.router.events.subscribe(event => {

			if (event instanceof ActivationEnd) {
				console.log(event.snapshot.url);
				
				this.urlLength = event.snapshot.url.length;
				console.log(this.urlLength);
				console.log(window.location.href);
				this.url =window.location.href
				if (this.urlLength == 0) {
					changeDetectorRef.detectChanges();
					this.isHome = true
				  } else {
					this.isHome = false
					changeDetectorRef.detectChanges();
				  }
				this.loadSections();
			}
		});


		this.authService.userBS.subscribe(user => {

			if (user) {
				this.username = user.name || user.username;

				if (user.admin) {
					this.homePath = '/admin/';
				}
				else {
					this.homePath = '/';
				}
			}
		});

		this.mobileQuery = lmedia.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	get router() { return this.routerService.router; }

	get xs(): boolean {
		return this.media.isActive('xs');
	}


	get uploading() { return this.profileService.uploading; }


	back() {

		if (this.showBack)
			this.routerService.back();
	}


	ngOnInit() {

		this.profileService.photoUrl.subscribe(url => {

			this.photoUrl = url;

			if (!this.changeDetectorRef['destroyed'])
				this.changeDetectorRef.detectChanges();
		});
	}


	openHome() {

		if (this.homePath)
			this.router.navigate([this.homePath]);
	}


	signOut() {

		this.authService.signOut();
	}



	menu(view: any) {

		Menu.popup({
			view: view,
			actions: ['Perfil (' + this.username + ')', 'Salir'],
			cancelButtonText: "Cancelar"
		})
			.then(action => {

				if (action) {
					if (action.title == 'Perfil (' + this.username + ')') {
						this.router.navigate([this.homePath + 'profile'])
					}
					else if (action.title == 'Salir') {
						this.signOut();
					}
				}
			});
	}

	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
	
	loadSections() {

		if (this.authService.user.admin) {
			this.sections = [
				{ icon: 'user', text: 'Clientes administradores', path: '/admin/customers' },
				{ icon: 'money', text: 'Administrar ofertas', path: '/admin/offers' },
				{ icon: 'catalog', text: 'Catálogo de productos', path: '/admin/products' },
				{ icon: 'receipt', text: 'Aprobar pagos realizados', path: '/admin/orders' }
			];
		}
		else {
			this.sections = [
				{ icon: 'tracking', text: 'Compras', path: '/purchases', module: 'purchases' },
				{ icon: 'finances', text: 'Finanzas', path: '/finances' },
				{ icon: 'catalog', text: 'Productos', path: '/products', module: 'products' },
				{ icon: 'market', text: 'Compras', path: '/shopping', module: 'shopping' },
				{ icon: 'business', text: 'Acerca', path: '/company', module: 'company' }
			];

			if (this.authService.user.role == 'admin')
				this.sections.push({ icon: 'user', text: 'Usuarios', path: '/users' });
		}
	}

	openSection(index: number) {

		const section = this.section(index);

		if (section.hidden || !section.path) return;

		this.router.navigate([section.path]);
	}

	section(index: number) : HomeSection {

		const section = this.enabledSection(index);

		if (section.module)
		{
			const user = this.authService.user;

			section.disabled = (!user.admin && user.role != 'admin') && (user.modules == null || user.modules.indexOf(section.module) < 0);
		}

		return section;
	}

		enabledSection(index: number) : HomeSection {

		if (this.sections && this.sections.length > 0)
		{
			if (index < this.sections.length)
			{
				return this.sections[index];
			}
			else
			{
				const section = {...this.sections[0]};
				section.hidden = true;

				return section;
			}
		}
		else
		{
			return null;
		}
	}



}
